.finder__search {}

.finder__title {
  @extend .h2;
  margin: 1.5rem 0 1rem 2.5rem;
}

.finder__search__input__wrap {
  position: relative;
  display: inline-block;
  width: 100%;
  @include mq(64em) {
    width: 50%;
  }
}

.finder__search__input {
  background: $gray-lighter;
  height: 3.5rem;
  padding: 1rem;
  width: 100%;
}

.finder__search__icon {
  position: absolute;
  width: 2rem;
  right: 0.5rem;
  top: 0.8rem;
  color: $gray;
  padding: 0.25rem;
}

.clear-filter {
  margin-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  @include mq(64em) {
    margin-top: 0;
    margin-left: 1rem;
  }
}

.finder__sort {
  padding: 1rem 0;
  background: $gray-lighter;
  ul {
    @include clean;
    list-style: none;
  }
}

.finder__sort__label {
  @include font-size(15);
  font-weight: $bold;
}

.finder__sort__item {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  width: 100%;
  margin-bottom: 1rem;
  @include mq(64em) {
    width: 25%;
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
  &:first-child {
    @include mq(64em) {
      width: 10%;
      margin-right: 0;
    }
  }
}

.finder__dropdown {
  position: relative;


}

.finder__dropdown__button {
  display: block;
  position: relative;
  background-color: $red;
  color: $white;
  @include font-size(15);
  font-weight: $bold;
  line-height: 3.25;
  text-align: center;
  padding-right: 3rem;
  border: 0.1rem solid $red;
  .finder__dropdown--active &,
  .dropdown--active & {
    background: $white;
    color: $red;
  }
}

.finder__dropdown__arrow {
  position: absolute;
  display: block;
  width: 3rem;
  height: 3rem;
  color: $red;
  top: 0;
  right: 0;
  background: $white;
  padding: 1.2rem 1rem 1rem;
  border-left: 0.1rem solid $red;
}

.finder__dropdown__arrow__open {
  @include show;
  .finder__dropdown--active &,
  .dropdown--active & {
    @include hide;
  }
}

.finder__dropdown__arrow__close {
  @include hide;
  .finder__dropdown--active &,
  .dropdown--active & {
    @include show;
  }
}

.finder__dropdown__list {
  @include hide;
  width: 100%;
  z-index: 1;
  text-align: center;
  background: $red;
  a {
    display: block;
    color: $white;
    background-image: none;
    margin: 1rem 0;
    &:hover {
      color: $white;
      text-decoration: underline;
      background-image: none;
    }
  }
  .finder__dropdown--active & {
    @include show;
    position: absolute;
  }
}

.finder__grid {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 2rem;
}

.finder__grid--hidden {
  @include sr-hide;
}

.finder__grid__left {
  display: flex;
  flex-flow: column wrap;

  width: 100%;
  @include mq(48em) {
    float: left;
    width: 90%;
  }
}

.finder__card {

  padding: 1.5rem;
  border-bottom: 0.1rem solid $gray-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mq(48em) {
    margin: 0 1rem;
  }
  @include mq(64em) {
    display: inline-block;
  }
}

.finder__card__link {
  color: $red;
  @include font-size(18);
  display: inline-block;
  @include mq(64em) {
    line-height: 1.8;
  }
  @include mq(80em) {
    margin-right: 4rem;

  }
}

.finder__card__degree {
  color: $red-dark;
  @include font-size(14);
  background: $gray-lighter;
  padding: 0.5rem;
  margin: 0.25rem 0.5rem 0.25rem 0;
  transition: background $transition,
  color $transition;
  display: inline-block;
  @include mq(64em) {
    margin: 0 0.5rem;
  }
  &:hover {
    background: $red-dark;
    color: $white;
  }
}

.finder__card__degree__group {
  float: right;
  text-align: right;

  @include mq(64em) {
    display: inline-block;
  }
}
