.wrap {
  @include clear;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;

  @include mq(48em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @include mq(90em) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.wrap--minimum {
  padding: 1rem;
}

.wrap--wide {
  max-width: 96rem;
}

.wrap--narrow {
  max-width: 72rem;
}

.wrap--slim {
  max-width: 48rem;
}

.wrap--relative {
  position: relative;
}

.main,
.sidebar {
  @include spacing(1);
  @include mq(64em) {
    @include spacing(4);
  }
}

.main {
  @include spacing(2);
  @include mq(64em) {
    @include spacing(4);
    width: calc(100% - 22rem);
    float: right;
  }

  @include mq(90em) {
    width: calc(100% - 24rem);
  }
}

.main--center {
  @include mq(64em) {
    margin-right: auto;
    margin-left: auto;
    float: none;
  }
}

.sidebar {
  @include mq(64em) {
    width: 18rem;
    float: left;
  }
}

.main + .sidebar + .main {
  @include mq(64em) {
    margin-top: 0;
  }
}

.sidebar + .main + .sidebar {
  @include mq(64em) {
    margin-top: -5rem;
  }
}

.background--red {
  background: $red;
  color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background--gray-lighter {
  background: $gray-lighter;
}

.section {
  @include spacing(4);
}

.section--large {
  @include spacing(6);
}
