.align-left,
.align-right {
  margin-bottom: 1.25rem;

  @include mq(48em) {
    max-width: 50%;
  }
}

.align-left {
  @include mq(48em) {
    margin-right: 1.25rem;
    float: left;
  }
}

.align-right {
  @include mq(48em) {
    margin-left: 1.25rem;
    float: right;
  }
}

.align-small {
  @include mq(48em) {
    max-width: 25%;
  }
}

.align-zero {
  @include mq(48em) {
    margin-bottom: 0;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}


.hide {
  @include hide;
}

.skip a {
  position: absolute;
  left: -10000rem;
  top: -2rem;
  width: .1rem;
  height: .1rem;
  overflow: hidden;
  z-index: 100;
  color: $white !important;
  outline: 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-bottom-right-radius: .3rem;
  transition: top 1s ease-out;
  &:focus {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    background: $red;
    transition: top .1s ease-in;
  }
}

.mobile--hidden {
  position: absolute;
  left: -999rem;
  @include mq(64em) {
    position: static;
    left: 0;
  }
}

.break {
  &:after {
    content: "\000A";
    white-space: pre;
    @include mq(64em) {
      content: "\000A";
    }
  }
}


.bg-red{
  background-color: $red;
}

.color-red{
  color: $red;
  max-width: 100%;
  @include mq(64em) {
    max-width: 47%;
  }
}


.padding-area{
  z-index: 2;
  position: relative;
  padding:2rem 0;
  @include mq(64em) {
    padding:3rem 0;
    margin:1rem 0;
  }
}

.mobile-stats{
  margin-top:13rem;
  @include mq(64em) {
    margin-top:0;
  }
}


.visually-hidden {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.bg-grey{
  background-color:#e2e2e2;
}

.text-black{
  color:black!important;
}