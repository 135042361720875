.alert-fixed {
  position: static;
  top: 4.9rem;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 3rem 3rem;
  background-color: #497A7B;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  
  @include mq(64em) {
    flex-direction: row;
    top: 9.5rem;
  }

  .close {
    position: absolute;
    top: 2rem;
    right: 1.5rem;
    color: inherit;
    z-index: 2;
    width: 1rem;

    @include mq(64em) {
      position: static;
      width: 1.2rem;
    }
  }

  .alert-text-wrapper {
    max-width: 44rem;
    flex: 1;
    .paragraph-wrapper {
      display: none;
      margin-top: 1rem;

      &.expanded {
        display: block;
      }
    }
  }

  .subheading-button-alert {
    background-color: #fff;
    padding: 0.7rem 1.4rem;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 22.5rem;
    max-width: 100%;
    margin-top: 2rem;
transition: all 250ms ease-in;
    @include mq(64em) {
      margin-top: 0;
      width: 16.5rem;
      margin-left: 19rem;
      margin-right: 2rem;
    }

    .text-item {
      font-size: 1.3rem;
      font-family: inherit;
    }

    .svg-item {
      width: 1.5rem;

      svg {
        width: 100%;
      }
    }
  }

  .subheading-button-alert:hover{
background-color: rgba(237, 236, 236, 0.885);
box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  }
}

.alert-heading {
  font-size: 1.3125rem;
  font-weight: 700;
}


.paragraph-wrapper {
  display: none;
  margin-top: 1rem;
}

.paragraph-wrapper.expanded {
  display: block;
}


.dropdown-icon{
  transform: rotate(90deg);
  position: absolute;
}

.dropdown-icon.rotate-item {
  transform: rotate(-90deg);
  transition: transform 0.3s ease-in-out;
}


