.figure__item {
  background: $red;
  color: $white;
  text-align: center;
  padding: 2rem;
  margin-bottom: 0.2rem;
  .hero__banner & {
    background: transparent;
    padding: 0;
  }
}


.figure__number {
  font-weight: $bold;
  @include font-size(65);
}

.figure__text {
  @include font-size(18);
  font-weight: $reg;
}
