.sidebar__panel {
  position: relative;
  margin-bottom: 3rem;

  padding: 3rem 0;
  border-bottom: 0.1rem solid #CDCBCB;
  @include mq(48em) {
    margin-left: 1.5rem;
  }
  & + & {
    padding-top: 0;
  }
  &:after {
    position: absolute;
    content: '';
    border-bottom: 0.1rem solid #CDCBCB;
    padding-bottom: 3rem;
    width: 500%;
    left: -500%;
    right: 0;
  }
}

.sidebar__panel--info {
  @include clean;
  list-style-type: none;
}

.sidebar__news {
  border: 0.1rem solid #979797;
  @include mq(48em) {
    margin-left: 1.5rem;
  }
}

.sidebar__news__content {
  padding: 2rem 1.5rem;
}

.sidebar__logo {
  @include fill($svgstore--LeadingThroughExcellence_Stacked_2color, $black);
  width: 18rem;
  min-height: 6rem;
  background-size: auto 100%;
  margin-bottom: 2.5rem;
  background-position: center;
  @include mq(48em) {
    margin-top: -1.5rem;
  }
}
