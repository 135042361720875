.hero {
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include mq(80em) {
    height: calc(100vh - 5rem);
    overflow: hidden;
    img {
      object-fit: cover;
      max-height: calc(100vh - 5rem);
      width: 100vw;
      // polyfil for IE
      font-family: 'object-fit: cover;'
    }
  }
  video {
    height: 50vh;
    max-height: calc(100vh - 5rem);
    width: 100vw;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    @include mq(80em) {
      height: auto;
      max-height: calc(100vh - 5rem);
    }
  }
}

.hero-secondary {
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include mq(80em) {
    // height: calc(100vh - 5rem);
    overflow: hidden;
    img {
      object-fit: cover;
      // max-height: calc(100vh - 5rem);
      width: 100vw;
      // polyfil for IE
      font-family: 'object-fit: cover;'
    }
  }
  video {
    height: 50vh;
    // max-height: calc(100vh - 5rem);
    width: 100vw;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    @include mq(80em) {
      // height: auto;
      // max-height: calc(100vh - 5rem);
    }
  }
}

.hero__overlay {
  bottom: 0;
  background: rgba($blackish, .84);
  padding: 2rem;
  @include mq(64em) {
    position: absolute;
    left: 2rem;
    width: 50%;
  }
  .hero__title {
    display: inline-block;
    margin-left: -2rem;
    margin-bottom: 2rem;
  }
  p {
    color: $white;
  }
  a {
    color: $white;
  }
}

@media print {
  .hero__overlay {
  
    .hero__title {
      color: black !important;
    }
    p {
      color: black !important;
    }
    a {
      color: black !important;
    }
  }
}


.hero__overlay-alt {
  bottom: 0;
  background: rgba($blackish, .84);
  padding: 2rem;
  @include mq(64em) {
    position: absolute;
    left: 2rem;
    width: 50%;
  }
  .hero__title {
    display: inline-block;
    margin-left: -2rem;
    margin-bottom: 2rem;
  }
  p {
    color: $white;
  }
  a {
    margin-top: 3rem;
    color: $white;
    cursor: pointer !important;
  }
  .hero-button:hover{
  background-color: black;
  color:white;
  cursor: pointer;
  z-index:3;
  }
}

.hero__overlay-alt-new {
  bottom: 0;
  background: transparent;
  padding: 2rem;
  position: absolute;
  @include mq(64em) {
    position: absolute;
    left: 2rem;
    width: 50%;
  }
  .hero__title {
    display: inline-block;
    // margin-left: -2rem;
    margin-bottom: 2rem;
    background-color: transparent;
    font-size: 3rem;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: -1rem;
    @include mq(64em) {
      font-size: 4rem;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-left: -3rem;
    }
  }

  a {
    margin-top: 1.5rem;
    color: $white;
    cursor: pointer !important;
  }
  .hero-button:hover{
  background-color: black;
  color:white;
  cursor: pointer;
  z-index:3;
  }
}

.hero__overlay-alt-new-secondary {
  bottom: 0;
  background: transparent;
  padding: 2rem;
  position: absolute;
  @include mq(64em) {
    position: absolute;
    left: 2rem;
    width: 80%;
  }
  .hero__title {
    display: inline-block;
    // margin-left: -2rem;
    margin-bottom: 2rem;
    background-color: transparent;
    font-size: 3rem;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: -3rem;
    @include mq(64em) {
      font-size: 4rem;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-left: -3rem;
    }
  }

  a {
    margin-top: 1.5rem;
    color: $white;
    cursor: pointer !important;
  }
  .hero-button:hover{
  background-color: black;
  color:white;
  cursor: pointer;
  z-index:3;
  }
}

@media (max-width: 767px) {

    .hero__title-secondary {
      margin-left:-1rem!important;
    }
  
  
  
  
}






.hero__title {
  background: $red;
  color: $white;
  padding: 0 1rem;
  @include mq(48em) {
    padding: 0 3rem;
  }
}


//

.hero__grid__wrap {
  position: relative;
}

.hero__grid {
  @include clear;
  display: flex;
}

.hero__grid__item {
  margin: 0.05rem 0;
  width: 100%;
  @include mq(48em) {
    width: 50%;
  }

  & + & {
    margin-left: 0.05rem;
    margin-right: 0.05rem;
  }
  &:last-child {
    margin-right: 0;
  }
  &:nth-child(2),
  &:nth-child(3) {
    display: none;
    @include mq(48em) {
      display: block;
      width: 25%;
    }
  }
}


.hero__grid__card {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  background: $black;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.hero__grid__card__text {
  margin-right: 1rem;
  color: $gray;
  @include fluid-type(font-size, $minScreen, $maxScreen, 42px, 60px);
  font-weight: $bold;
  display: inline-block;
  text-transform: uppercase;
}

.card__text--red {
  color: $red;
}

.hero__grid__card__image {
  @include fill($svgstore--TheGov, $black);
  width: 6rem;
  height: 6rem;
  display: inline-block;
  background-size: 100% 100%;
}

//

.hero__slider {
  position: relative;
  .hero__title {
    margin-bottom: 0.25rem;
    display: inline-block;
    @include fluid-type(font-size, $minScreen, $maxScreen, 26px, 40px);
  }
  .hero__title__wrap {
    position: absolute;
    z-index: 1;
    top: 5%;
    // background: $red;
    margin-bottom: 0;
    @include mq(64em) {
      background: transparent;
      position: absolute;
      top: 15%;
      left: 0;
      display: inline-block;
      z-index: 1;
    }
  }
}

.hero__slide__img {
  position: relative;
  z-index: 0;
}

.hero__slide__container {
  position: relative;
  @include mq(64em) {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
  }
  @include mq(80em) {
    width: 50%;
    left: 2rem;
  }
}

.hero__slide__content {
  background: rgba($blackish, .84);
  padding: 1rem;
  color: $white;
  @include mq(80em) {
    padding: 2rem;
  }
}

.hero__slide__title {
  @extend .h4;
}

//

.hero__banner {
  background: $blackish;
  padding: 0 0 2rem 0;
  @include mq(64em) {
    padding: 2rem 2rem 1.5rem;
  }
  .hero__title {
    margin-bottom: 0.25rem;
    display: inline-block;
    @include fluid-type(font-size, $minScreen, $maxScreen, 26px, 40px);
  }
}

.hero__banner__grid {
  margin: 0 auto;
  @include mq(64em) {
    display: flex;
  }

}

.hero__banner__grid__item--1 {
  margin-top: -4.5rem;
  width: 100%;
  @include mq(80em) {
    margin-top: 0;
    width: 50%;
  }
}

.hero__banner__grid__item--2 {

  @include mq(32em) {
    float: left;
    width: 33%;
    padding: 0 1rem;
  }
  @include mq(80em) {
    width: 20%;
  }
}

.hero__banner__wrap {
  position: relative;
}

.hero__banner__cta {
  background: rgba($blackish, .7);
  padding: 1rem;
  @include mq(48em) {
    padding: 0 1rem;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.hero__banner__cta__link {
  display: block;
  padding: 0.5rem 0;
  font-weight: $reg;
  color: $white;

  &:hover {
    text-decoration: underline;
  }
  @include mq(48em) {
    display: inline-block;
    padding: 1.5rem 1rem;
  }
}

.hero__home {
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
}

.hero__logo {
  color: $white;
  background: $red;
  padding: 1.5rem;

  @include mq(48em) {
    width: 30rem;
  }
}
