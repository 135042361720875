.table {
  margin-bottom: 2rem;
  th,
  td {
    padding: 0.5rem 1rem 0.5rem 0;
    text-align: left;
    vertical-align: top;
  }

  th {
    font-weight: $book;
    font-style: italic;
  }
}

.table--full {
  margin-bottom: 0;
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0.125rem 0;
  }
}

.table__line {
  position: relative;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: -0.5rem;
    width: 2.5rem;
    background: $black;
    height: 0.1rem;
  }
}


.table__input {
  @include clear;
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 7rem 0 -7rem 0;
  list-style: none;
  .department-mod & {
    margin: 6rem 0 -13rem 0;
  }
  @include font-size(12);
  @include mq(58em) {
    float: right;
    text-align: right;
    width: calc(100% - 24rem);
    margin: 2rem 0 -3rem 0;
    .department-mod & {
      margin: 2rem 0 -3rem 0;
    }
  }
  li {

    margin-bottom: 0;
    @include mq(58em) {
      float: left;
    }
    &:last-child {
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: block;
      text-align: right;
      @include mq(58em) {
        float: right;
        margin-bottom: 0;
      }
    }
  }
}

.table__faculty__toggle {
  text-align: right;
  color: $red;
  @include underline;
  &:hover,
  &:focus {
    color: $gray;
    @include underline($gray);
  }
}

.form__label {
  text-indent: -9999px;
  display: block;
  font-weight: bold;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  @include mq(58em) {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }

}

.search-label {
  span {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
  }
}

.form__input {
  height: 3rem;
  border: 1px solid $red;
  padding: 0 1rem;
  margin-bottom: 3rem;
  .department-mod & {
    margin-bottom: 9rem;
  }

  @include mq(58em) {
    margin-bottom: 2rem;
    .department-mod & {
      margin-bottom: 2rem;
    }
  }
}

.form__select {
  position: relative;


  @include mq(58em) {
    display: inline-block;
    vertical-align: middle;
  }
  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
  }
}

.form__select__text {
  height: 3rem;
  padding: 0 3rem 0 1rem;
  margin: 0;
  line-height: 4;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $white;
  background-color: $red;
  min-width: 18rem;
  text-align: center;
}


.form__select__arrow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  color: $red;
  pointer-events: none;
  background-color: $white;
  border: 1px solid $red;
  span {
    margin-top: 0.25rem;
  }
}

.form__select__text--focus {
  outline-color: rgb(77, 144, 254);
  outline-offset: -2px;
  outline-style: auto;
  outline-width: 5px;
}
