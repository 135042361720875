.wf-loading * {
  opacity: 0;
}

* {
  box-sizing: border-box;
}

:root {
  font-size: 100%; // temp sanitize.css bug fix
  word-wrap: break-word;
 
}


@media print {
  /* Styles for print view */
  body {
   
    color: black!important;
  }

img{
  display: none!important;
}

}

button {
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  fill: currentColor;
}

