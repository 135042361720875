// feature panel

.feature-panel-container {
    display: flex;
     flex-direction: column;
    @include mq(65em) {
      position: relative;
    width: 100%;
    overflow: hidden;
    }
  }

  .slanted-image-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .slanted-image {
   display: none;
    @include mq(80em) {
      display: block;
      position: absolute;
    top: 0;
    right: -20%;
    width: 75%;
    height: 100%;
    background-color: $red;
    transform: skewX(20deg);
    z-index: 1;
    border-left: 0.4rem solid white; 
    }
  }

  .slanted-image-mobile,.content-mobile{
    display: block;
    @include mq(80em) {
   display: none;
    }
  }

  .slanted-image-mobile{
    background-color: $red;
    display: grid;
    place-content: center;
    padding:2rem 2rem;
    @include mq(80em) {
      display: none;
       }
  }
  .content-mobile{
    max-width: 35rem;
    color:$white;
  }

  .slanted-image img {
    display: block;
    width: 100%;
    height: auto;
    z-index: 2;
  }

  .content {
display: none;
  
  .text{
    margin-bottom: 4rem;
  }
  @include mq(80em) {
    display: block;
    position: absolute;
  top: 4rem;
  right: 6rem;
  z-index: 3;
  padding: 20px;
  max-width: 35rem;
  margin-left: 25%; /* Adjust this value as needed */
  color: $white;
  }
  }

  .text h1,
  .text p {
    margin: 0;
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    
  }

  // .feature-panel-hover{
   
  //   @include mq(65em) {
  //     .subnav-variation-dropdown__toggle:hover + .subnav-variation-dropdown__icon {
  //       display: block;
  //     }
      
  //   }
    
  
  // }

 


// feature-panel-alt

.feature-panel-alt{
  display: flex;
  flex-direction: column;

  @include mq(65em) {
    display: flex;
flex-direction: row;
// align-items: center;
justify-content: center;
  }
  .text{
    margin-bottom: 5rem;
    
  }
  .buttons{
    
  }
}

.content-feature-alt{
  flex: 1;
  display: flex;
  justify-content: center;
}

.content-feature-alt-inner{
  max-width: 33rem;
  z-index:4;
 padding:2rem 1rem;
  @include mq(65em) {
    padding:0 2rem;
    
  }
  .text{
    @include mq(65em) {
      padding-top:5rem;
      
    }
  }
}


.image-feature{

}

.container-feature-image{
  position:relative;
display: contents;
@include mq(65em) {
  display: block;
  transform: scale(1.4);
  margin-top: 6.5rem;
}
}

.slanted{
  position: absolute;
  left:-7.5rem;
  top: 0;
   width: 14rem;
   height: 100%;
   background-color: $white;
   transform: skewX(20deg);
   z-index: 1;
   display: none;
   @include mq(65em) {
   display: block;
      }
}

