.dropdown {
  position: relative;
  margin-bottom: 2rem;
}



.dropdown__toggle {
  display: block;
  position: relative;
  padding-left: 1rem;
  background-color: $black;
  color: $white;
  font-weight: $bold;
  text-transform: uppercase;
  line-height: 3;
  .dropdown--active & {
    background: $red;
  }
  .dropdown--subnav & {
    @include mq(64em) {
      display: none;
    }
  }
  .dropdown--alt & {
    background-color: $white;
    color: $red;
    border-bottom: 0.2rem solid $red;
  }
}

.dropdown__toggle--alt {
  @extend .dropdown__toggle;
  background: $white;
  transition: background $transition;
  &:hover {
    background: $red;
    color: $white;
    .dropdown__icon--alt {
      color: $white;
    }
  }
  .dropdown--active & {
    background: $red;
    color: $white;
  }
}

.dropdown__icon {
  position: absolute;
  display: block;
  width: 3rem;
  height: 3rem;
  color: $white;
  right: 0;
  top: 0;
  background: $red;
  padding: 1rem;
  .dropdown--active & {
    transform: rotate(180deg);
  }
}

.dropdown__icon--alt {
  position: absolute;
  display: block;
  width: 3rem;
  height: 3rem;
  color: $red;
  right: 0;
  top: 0;
  padding: 1rem;
  transition: transform 200ms ease-out;
  .dropdown--active & {
    transform: rotate(-45deg);
    color: $white;
  }
}

.dropdown__list {
  @include hide;
  width: 100%;
  list-style: none;
  z-index: 1;
  .dropdown--active & {
    left: 0;
  }

  .dropdown--subnav & {
    @include mq(64em) {
      @include show;
    }
  }
  .dropdown--alt & {
    @include clean;
    background: $red;
  }
}


.dropdown__link {
  display: block;
  @include font-size(15);
  font-weight: $reg;
  color: $white;
  padding: 0.5rem 1.5rem;
}

