.faculty {
  @include spacing(1);
  border-top: 0.5rem solid $gray-lighter;
}


.faculty__item {
  @include clear;
  padding: 2rem 1rem;
  border-bottom: 0.1rem solid $gray;
}

.faculty__img {
  border-radius: 50%;
  @include mq(48em) {
    float: left;
  }
}

.faculty__content {
  overflow: hidden;
  padding: 1rem;
  line-height: 1.6;
}

.faculty__name {
  @include font-size(18);
  font-weight: $reg;
  margin-bottom: 0.5rem;
}

.faculty__credentials {
  @include font-size(15);
  font-weight: $reg;
}

.faculty__title {
  font-weight: $book;
  margin-bottom: 0.5rem;
}
