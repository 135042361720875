.router-map{
background-color: white;
width: 100%;
padding-top:6rem;
padding-bottom: 0;
@include mq(65em) {
    background-color: black;
    padding-bottom: 6rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  }
}

.flex-item-router{
    display: flex;
    justify-content: center;
    gap:0;
    flex-direction: column;
    @include mq(65em) {
        flex-direction: row;
        gap:1rem;
      }
}

.router-map-content{
    max-width:30rem;
  
    .text{
        color: $white;
        margin-bottom: 4rem;
    }
}


.router-map-left{
flex-basis: 50%;
background-color:black;
display: grid;
place-content: center;
padding:4rem 1rem;
@include mq(65em) {
    background-color:black;
  }
}

.router-map-right{
flex-basis: 50%;
position: relative;
margin-top:0;
@include mq(65em) {
    // margin-top:8rem;
  }
}

.contact-us-item{
position: static;
background-color: #497A7B;
color:$white;
display: grid;
place-content: center;
z-index:2;
padding:1.5rem 3rem;
left:8.5rem;
@include mq(65em) {
    background-color: #497A7B;
    color:$white;
    position: absolute;
    z-index:2;
    padding:1.5rem 3rem;
    left:8.5rem;
    top:-3rem;
  }
}

.contact-us-item-inner{
max-width:14rem;
}


.map-item{
    position: static;
    // padding-bottom: 50%;
   margin-top:0;
   @include mq(65em) {
    position: absolute;
    // margin-top:6rem;
  }
  iframe{
    width:100%;
    height:539px;
    @include mq(65em) {
        width:601px;
    height:539px;
      }
   
  }
  }
  
