.footer {
  @include clear;
  background: $black;
  // margin-top: 0.15rem;
}

.footer__grid {
  @include clear;
  padding-top: 4rem;
  padding-bottom: 4rem;
  ul {
    margin-top: 2rem;
  }
  li {
    padding-right: 2rem;
    @include mq(80em) {
      padding-right: 4rem;
    }
  }
}

.footer__locations {
  @include clean;
  float: left;
  li {
    margin-bottom: 1.5rem;
  }
  a {
    font-weight: $bold;
    color: $white;
    text-decoration: none;
    @include underline($gray);
    @include font-size(18);
    padding-bottom: 0.25rem;
    background-size: 100% .25rem;
    &:hover {
      padding-bottom: 0.25rem;
      background-size: 100% .25rem;
    }
  }
}
.footer__gov__logo {
  display: block;
  width: 10rem;
  float: left;
  margin-right: 4rem;
  @include mq(80em) {
    width: 13rem;
    margin-right: 5rem;
  }
}

.footer__link__list {
  @include clean;
  float: left;
  li {
    margin-bottom: 1.5rem;
  }
  a {
    @include font-size(15);
    font-weight: $bold;
    color: $white;
    text-decoration: none;
    @include underline($gray);
    &:hover {
      color: $gray;
      @include underline($gray);
    }
  }
}

.footer__icons {
  @include clean;
  float: left;
  margin-top: 0 !important;
  li {
    width: 10rem;
    padding-right: 0;
    margin-bottom: 3rem;
  }
  a {
    display: block;
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.footer__social {
  text-align: center;
  background: $red;
  padding: 1rem;
  @include mq(72em) {
    position: absolute;
    width: 5rem;
    right: 0;
    top: 0rem;
    bottom: 0;
    height: 100%;
    overflow: hidden;
  }
}

.footer__social__list {
  @include clean;
  list-style: none;

  @include mq(72em) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
  li {
    display: inline-block;
    margin-bottom: 0;
    margin: 0 1rem;
    vertical-align: middle;
    @include mq(72em) {
      margin-bottom: 2rem;
    }
  }
}

.footer__social__link {
  display: block;
  color: $white;
  width: 2rem;
}
