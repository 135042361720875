.juicer {
  @include clear;
  @include spacing(5);
  &:before {
    content: '' !important;
  }
}

.juicer__social__icon {
  display: inline-block;
  width: 1rem;
  vertical-align: middle;
  @include mq(48em) {
    margin-right: 0.5rem;
  }
}

.juicer__hide {
  @include hide;
  @include mq(48em) {
    @include show;
  }
}

.insta__grid {
  @extend .grid;
  margin-left: 0;
  margin-right: 0;
}

.insta__grid__item {
  @extend .grid__item;
  padding: 0;
  position: relative;
  overflow: hidden;
  img {
    @include mq(48em) {
      height: 640px;
      width: 640px;
      object-fit: cover;
      // polyfill for ie
      font-family: 'object-fit:cover;'
    }
  }
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    transition: background $transition;
  }
  &:hover {
    &:before {
      background: rgba($black, .55);
    }
    .image__meta {
      opacity: 1;
    }
  }
}


.insta__grid__item--2 {
  width: 100%;
  @include mq(48em) {
    float: left;
    width: 50%;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
}

.insta__small {
  img {
    @include mq(48em) {
      width: 320px;
      height: 320px;
    }
  }
}

.image__meta {
  display: block;
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
  opacity: 0;
  color: $white;
  text-align: center;
  width: 100%;
}

.image__meta__icon {
  width: 1rem;
  margin-right: 0.25rem;
  float: left;
}

.image__meta__item {
  display: inline-block;
  margin: 1rem;
}

.juicer__facebook__img {

}

.juicer-feed ul.j-filters {
  display: none !important;
}
