.stats-panel{
   padding:2rem 0;
   width:100%;

}

.stats-panel-inner{
    display: flex;
    gap:4rem;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    @include mq(65em) {
        flex-direction: row;
        // margin-left: 3.5rem;
        width: 100%;
        
      }
}

.heading-right{
    text-align: center;
}

.stats-panel-image{
    display: contents;
    @include mq(65em) {
        display: block;
        transform: scale(1.1);
      }
    // max-width:5rem;
   
    // flex:1;
    img{
   
    }
}

.stats-panel-content-outter{
    flex-basis: 45%;
    padding:0 1rem;

    h2{
        max-width: 22rem;
        font-size: 2.5rem;
       }
}

.stats-panel-content{
    display: grid;
    place-content: center;
    gap:1rem;
    @include mq(65em) {
        display: block;
        
      }
  
}

.stats-panel-content-container{
    width:100%;
    max-width:26rem;
    @include mq(65em) {
       
        
      }
}


.stats-panel-item{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
   max-width: 40rem;
    @include mq(65em) {
        max-width: 26rem;
        
      }
    span{
    
        svg{
           
        }
    }
}

.stats-panel-item-left{
    margin-bottom: 4rem;
h3{
    font-size: 3rem;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 1.2;
}
p{
    font-size: 1.1rem;
    margin-block-start: 0;
    margin-block-end: 0;
    color:black;
}
}

.last-stat{
    color:black;
}


.stats-mobile{
    @include mq(65em) {
       display:none;
      }
}

.stats-desktop{
    display: none;
    @include mq(65em) {
       display:block;
      }
}

.stat-red {
    color:#C41E3A;
}


.stat-alt {
    color:#497A7B;
}