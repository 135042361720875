.header__search {
  position: absolute;
  z-index: 1001;
  background: $gray;
  left: 100%;
  transition: transform $transition;
  width: 100%;
  visibility: hidden;
  @include mq(64em) {
    background: $red;
    top: 0;
    right: 0;
    transform: translateX(100%);
    width: calc(100% - 15rem);
    height: 5rem;
    display: flex;
    align-items: center;

  }
  .search-active & {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.header__search__wrap {
  margin: 0 auto;
  background: $gray-dark;

  @include mq(64em) {
    background: transparent;
    margin-right: 3rem;
  }
}

.header__search__form {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  @include mq(64em) {
    width: auto;
    padding: 0;
  }
}

.header__search__list {
  @include clean;
  list-style: none;
  @include mq(64em) {
    float: left;
    display: table;
    height: 2rem;
  }

}

.header__search__item {
  border-top: .1rem solid $red;
  margin-bottom: 0;
  @include mq(64em) {
    border: 0;
    display: table-cell;
    vertical-align: middle;
    @include fluid-type(font-size, 1024px, 1440px, 13px, 16px);
    @include fluid-type(padding-right, 1024px, 1440px, 5px, 25px);
    @include fluid-type(padding-left, 1024px, 1440px, 5px, 25px);
  }
}

.header__search__link {
  color: $white;
  text-transform: uppercase;
  margin: 0 1rem;
  padding: 1.25rem;
  display: block;
  @include mq(64em) {
    margin: 0;
    padding: 0;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }
}

.header__search__input {
  background: white;
  display: inline-block;
  height: 2rem;
  padding: 0.5rem;
  width: 100%;
  margin-right: 1rem;
  vertical-align: middle;
  @include mq(64em) {
    width: 28rem;
  }
}


.header__search__input__wrap {
  position: relative;
}

.header__search__button {
  position: absolute;
  display: block;
  width: 1rem;
  color: $red;
  right: 0.5rem;
  top: 0.25rem;
  @include mq(64em) {
    right: 1.5rem;
  }
}

.header__search__close {
  display: none;
  @include mq(64em) {
    display: inline-block;
    color: $white;
    width: 1rem;
    vertical-align: middle;
  }
}
