.timeline {
  position: relative;
  padding: 1.5rem 0 10rem;
  display: none;
  .active & {
    display: block;
  }

  &:before {
    position: absolute;
    content: '';
    width: 0.1rem;
    height: 100%;
    left: 0;
    top: 0;
    background: $black;
    @include mq(48em) {
      left: 50%;
    }
  }
  &:after {
    content: '';
    clear: both;
    display: table;
    width: 100%;
  }
}

.timeline__item {
  position: relative;
  display: block;
  margin: 0 .5rem;
}

.timeline__dot {
  position: absolute;
  top: 33%;
  transform: translateY(-33%);
  left: 0;
  margin-left: -1rem;
  background: $white;
  color: $red;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  display: block;
  padding: 0.35rem;
  transition: all 400ms ease;
  @include mq(48em) {
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: -0.55rem;
  }
}

.timeline__content {
  width: 90%;
  margin-left: auto;
  margin-right: 0;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,.15);
  margin-bottom: 2rem;
  transition: all 400ms ease;
  @include mq(48em) {
    width: 45%;
    margin-bottom: -5rem;
  }

  &.left {
    @include mq(48em) {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

.timeline__text {
  padding: 1rem;
  width: 95%;
  p {
    margin-bottom: 0;
  }
}

.timeline__extra {
  @include sr-hide;
  .active & {
    @include sr-show;
  }
}

.timeline__arrow {
  @include fill($svgstore--DoubleArrow_Down, $red);
  width: 1.25rem;
  height: 2.25rem;
  margin-left: -0.55rem;
  background-size: 100% 100%;
  @include mq(48em) {
    margin: 0 auto;
  }
}

.timeline__goal {
  margin-top: 1rem;
  @include font-size(24);
  font-weight: $bold;
  @include mq(48em) {
    text-align: center;
    width: 60%;
    margin: 2rem auto 0;
  }
}

.timeline__group {
  display: flex;
  margin-bottom: 4rem;
}

.timeline__group__item {
  display: inline-block;
}

.timeline__group__img {
  border-radius: 50%;
  opacity: .3;
  transform: scale(.65);
  transition:
  transform 500ms ease,
  opacity 500ms ease;
  .active & {
    margin: 0 auto;
    opacity: 1;
    transform: scale(1);
    @include mq($max: 340px) {
      // transform: scale(.8);
      margin-right: -30px;
    }
  }
}

.timeline__group__content {
  @include sr-hide;
  margin-top: 1rem;
  text-align: center;
  opacity: 0;
  transition: opacity 500ms ease-in;
  .active & {
    @include sr-show;
    opacity: 1;
  }
}

.timeline__group__content__name {
  @include font-size(24);
  font-weight: $bold;
  margin-bottom: 0.25rem;
}

.timeline__group__content__detail {
  @include font-size(17);
  font-weight: $reg;
}

