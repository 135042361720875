// /* Slider */

.slick-slider {
  // position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  .grid__slider & {
    overflow: visible;
    margin-left: -60px;
  }
  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {

  // transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  &:focus {
    outline: none;
  }
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

.slider__nav {
  position: absolute;
  z-index: 1;
  display: block;
  right: 0;
  top: -4.5rem;
  @include mq(80em) {
    left: 0;
  }
}

.slider__nav--left,
.slider__nav--right {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  background: $blackish;
  cursor: pointer;
  color: $white;
  transition: background-color $transition;
  background-size: auto 40%;
  background-position: center;
  background-repeat: no-repeat;
  &:hover,
  &:focus {
    background-color: #4A4A4A;
    outline: 0;
  }
}


.slider__nav--right {
  @include fill($svgstore--Arrow_Small_Right, $white);
  background-repeat: no-repeat;
  margin-left: 0.25rem;
}

.slider__nav--left {
  @include fill($svgstore--Arrow_Small_Left, $white);
  background-repeat: no-repeat;
}
