
.swiper-container {
  width: 100%;
  position: relative;
}

.swiper-slide {
  width: 80%;
  margin-right: 20px;
  height: auto;
}


@media screen and (max-width: 768px) {
  .swiper-slide {
      width: 100%;
      margin-right: 0;
  }
}

.swiper-slide img {
  max-width: 100%;
  height: auto;
}

.swiper-pagination {
  position: absolute;
  bottom: 1rem;

  text-align: center;
}



.carousel-arrows-desktop{
  display: none;
  @include mq(64em) {
    display: block;
   }
}


.carousel1{
 
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
}

/* Modal Content */
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Close Button */
.modal-close {
  position: absolute;
  top: -2rem;
  right: 10px;
  cursor: pointer;
  z-index:6;
  color:white;
}


.carousel1-slide{
background-color: none!important;



}





.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: auto; 
  max-width: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.modal-carousel {
  width: 100%; 
}


.modal-carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}



.modal-carousel .swiper-button-next,
.modal-carousel .swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  cursor: pointer;
  // background-color: transparent;
  border: none;
  outline: none;
  font-size: 24px;
  color: #fff;
  transition: opacity 0.3s ease;
}

.modal-carousel .swiper-button-next {
  right: 10px;
  float: right;
  background-color: $red;
}

.modal-carousel .swiper-button-next::after{
  content: url("data:image/svg+xml,%3Csvg  width='25' height='30' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.89802 0.857422L0.927734 3.82771L5.87822 8.77819L0.927734 13.7287L3.89802 16.699L11.8188 8.77819L3.89802 0.857422Z' fill='white'/%3E%3C/svg%3E") !important;
 
   }

 

.modal-carousel .swiper-button-prev {
  left: 10px;
}



  .modal-carousel .swiper-button-next,
.modal-carousel .swiper-button-prev {
 
  @media screen and (max-width: 1170px) {
    position: static;
    transform: none;
    margin-top: 10px;
    border-radius: 50%;
  }
}






  .modal-pagination {
    text-align: center;
    left: 45%!important;
    bottom:15rem!important;
    position: absolute!important;
    // bottom: 30px!important;

    z-index: 10!important;
    @include mq(64em) {
      margin-top: 6rem;
      text-align: center;
      left: 48%!important;
    }
  }

  .modal-pagination .swiper-pagination-bullet {
    margin: 0 3px;
  }

  
  
  .modal-pagination {
    transform: translate3d(-20px, 12.5rem, 0)!important;
    @include mq(64em) {
 
    }
  }

  .new-pagination-class {
    transform: translate3d(-20px, 14.5rem, 0) !important;
    @include mq(64em) {
      transform: translate3d(-10px, 14.5rem, 0) !important;
    }
  }





.carousel1-slide {
  position: relative;
}

.image-overlay {
  position: relative;
}

.image-overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg,transparent 20%,#000);
  opacity: .85;
  z-index: 1;
}

.image-overlay img {
  display: block;
  max-width: 100%;
  height: auto;
  z-index: 2;
}

.caption {
  position: absolute;
  bottom: 1.5rem;
  left:  1.5rem;
  color: #fff;
  font-size: 14px;
  z-index: 3;
}


