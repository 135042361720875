.subnav {
  width: 100%;
  margin-top: 0;
  margin-bottom: 4rem;
  border-top: 0.1rem solid #CDCBCB;
  list-style: none;
  display: none;
  padding-left: 0;
  background: $red;
  @include mq(64em) {
    background: $white;
    border-top: 0.3rem solid #CDCBCB;
  }

}

.subnav__item {
  display: flex;
  flex-wrap: wrap;
  // white-space: nowrap;
  margin-bottom: 0;
  @include mq(64em) {
    border-bottom: .1rem solid #E5E5E5;
  }

  a {
    color: $white;
    @include font-size(15);
    font-weight: $reg;
    line-height: 1.4;
    @include mq(64em) {
      color: $red;
    }
    &:hover {
      @include mq(64em) {
        background-color: $gray-lighter;
      }
    }
  }
}

.subnav__toggle {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  width: 2.9375rem;
  float: right;
  color: $red;
  background-image: none;
  padding: 1rem;
  .subnav__toggle--open {
    width: 1rem;
    display: block;
  }
  .subnav__toggle--close {
    width: 1rem;
    display: none;
  }
  .subnav__item--active > & {
    @include mq(64em) {
      background: $gray-lighter;
    }

    .subnav__toggle--open {
      display: none;
    }
    .subnav__toggle--close {
      display: block;
    }
  }
}

.subnav__link {
  display: block;
  order: -1;
  width: 100%;
  background-image: none;
  padding: 1rem 1rem 1rem 1.5rem;
  .subnav__toggle + & {
    width: calc(100% - 2.9375rem);
  }
  .subnav__item--active > & {
    @include mq(64em) {
      background: $gray-lighter;
    }
  }
}


.subnav--nested {
  @include hide;
  margin: 0;
  border-top: 0;

  padding: 0 0 1rem 0;
  @include mq(64em) {
    background: $gray-lighter;
  }
  .subnav__item--active > & {
    @include show;
  }
  .subnav__item {
    border: 0;
  }
}

.subnav .subnav__link--nested {
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  @include font-size(14);
  font-weight: $book;
}

// Horizontal subnav


.subnav--horizontal {
  @include clean;
  list-style: none;
  text-align: center;
  border-bottom: 0.1rem solid $red;
  padding: 0.5rem 0;
  li {
    display: inline-block;
    padding: 1rem;
    margin-bottom: 0;
  }
  a {
    @include font-size(15);
    font-weight: $reg;
    background-image: none;
  }
}
