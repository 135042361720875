.event__item {
  background: $gray-lighter;
  padding: 2rem;
  margin-bottom: 0.15rem;
}

.event__item--red {
  background: $red;
  color: $white;
}

.event__item--black {
  background: $blackish;
  color: $white;
  .event__grid__item & {
    justify-content: center;
  }
}

.event__date {
  @include fill($svgstore--Calendar, $black);
  background-repeat: no-repeat;
  width: 4rem;
  height: 5rem;
  color: $white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .event__item--red & {
    @include fill($svgstore--Calendar, $white);
    color: $red;
  }
}

.event__month {
  @include font-size(17);
}

.event__day {
  font-weight: $reg;
  @include font-size(28);
}

.event__title {
  @include font-size(24);
  font-weight: $reg;
  margin: 1rem 0;
  display: inline-block;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.event__details {
  @include font-size(15);
  font-weight: $bold;
  line-height: 1.5;
  margin-bottom: 1rem;
}

//

.event__grid__item {
  @extend .grid__item;
  @extend .grid__item--narrow;
  > * {
    width: 100%;
  }
}

.event__grid__card {

  @extend .grid__item--narrow;
  margin-bottom: 0.05rem;
}


.event__grid__img {
  width: 100%;
  object-fit: cover;
  @include mq(64em) {
    float: left;
    max-width: 50%;
  }
}

.event__grid__link {
  @include font-size(24);
  font-weight: $reg;
  a {
    color: $white;
    @include underline($white);
  }
}

.event__grid__card {
  width: 100%;
}
div.event__item--match {
  overflow: hidden;
}
