// colors
$white: #fff;
$black: #000;
$blackish: #202020;
$red: #bb1e3a;
$red-dark: #91182E;
$red-alt: #C41E3A;
$gray: #888A84;
$gray-dark: #333333;
$gray-light: #e2e2e2;
$gray-lighter: #efefef;
$gray-opacity: rgba($gray, 0.5);



// fonts
$book: 400;
$reg: 500;
$bold: 700;


$serif: 'adobe-garamond-pro', serif;
$sans-serif: 'Gotham SSm A', 'Gotham SSm B', sans-serif;





// transitions
$transition-speed: 500ms;
$transition-easing: ease;
$transition: $transition-speed $transition-easing;

// misc


