.grid {
  display: flex;
  flex-flow: row wrap;
  margin-right: -1rem;
  margin-left: -1rem;

}

.grid--center {
  justify-content: center;
}

.grid--bottom {
  align-items: flex-end;
}

.grid--slim {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.grid--narrow {
  margin-right: -0.15rem;
  margin-left: -0.15rem;
}

.grid--thick {
  margin-right: -2rem;
  margin-left: -2rem;
}

.grid__item {
  display: flex;
  flex-flow: column wrap;
  padding: 0 1rem;
}

.grid__item--match {
  flex-flow: row wrap;
  > * {
    display: flex;
    flex-flow: column wrap;
  }
}

.grid__item--narrow {
  padding: 0 0.05rem;
}

.grid__item--slim {
  padding: 0 0.5rem;
}

.grid__item--thick {
  padding: 0 2rem;
}

.grid__item--2 {
  @include mq(48em) {
    float: left;
    width: 50%;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
}

.grid__item--2-3 {
  @include mq(48em) {
    float: left;
    width: 40%;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
}

.grid__item--3 {
  width: 100%;
  @include mq(48em) {
    float: left;
    width: 33.333%;
    &:nth-child(3n+1) {
      clear: both;
    }
  }
}

.grid__item--3-2 {
  @include mq(64em) {
    float: left;
    width: 66.666%;
  }
}

.grid__item--4 {
  width: 100%;
  @include mq(48em) {
    float: left;
    width: 50%;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
  @include mq(64em) {
    width: 25%;
    &:nth-child(n) {
      clear: none;
    }
    &:nth-child(4n+1) {
      clear: both;
    }
  }
}

.grid__item--5 {
  @include mq(32em) {
    width: 100%;
    float: left;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
  @include mq(48em) {
    width: 100%;
    &:nth-child(n) {
      clear: none;
    }
    &:nth-child(3n+1) {
      clear: both;
    }
  }
  @include mq(64em) {
    width: 20%;
    &:nth-child(n) {
      clear: none;
    }
    &:nth-child(5n+1) {
      clear: both;
    }
  }
}

.grid__item--last {
  @include mq($max: 48em) {
    margin-top: 1rem;
  }
}
