.image {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  @include spacing(3);
  .align-left &,
  .align-right & {
    margin: 0;
  }
}

.image__caption {
  background: $blackish;
  color: $white;
  @include font-size(14);
  line-height: $baseline;
  padding: 1rem;
  .image--overlay & {

    width: 100%;
    background: rgba($blackish, .85);
    @include mq(48em) {
      position: absolute;
      bottom: 0;
    }
  }
}
