.button {
  @include font-size(15);
  padding: 1rem;
  display: block;
  background-color: transparent;
  color: $red;
  border: .15rem solid $red;
  font-weight: $reg;
  transition:
  color $transition,
  background-color $transition;
  &:hover {
    background-color: $red;
    color: $white;
  }
  @include mq(64em) {
    display: inline-block;
  }

  .background--red & {
    border-color: $white;
    color: $white;
    &:hover {
      background-color: $white;
      color: $red;
    }
  }

}

.button--alt {
  background-color: $red;
  color: $white;
  &:hover {
    background-color: transparent;
    color: $red;
  }
}

.button--full {
  display: block;
  width: 100%;
}


.button__list {
  @include clean;
  list-style: none;
  li {
    margin-bottom: 0.25rem;
  }
}

.button__group {
  @extend .ul--plain;
  @include spacing(2);
  @include mq(48em) {
    li {
      display: inline-block;
      margin-bottom: 0.25rem;
    }
  }
}

.button--large {
  padding: 1.25rem 5rem;
}

.outline--black {
  border-color: $black;
  color: $black;
  &:hover {
    background-color: $black;
    color: $white;
  }
}

.button--black {
  background-color: $black;
  color: $white;
  display: inline-flex;
  text-transform: uppercase;
  border: 0;
  &:hover {
    color: $red;
    background-color: $white;
  }
}

.button--red-dark {
  background-color: $red-dark;
  color: $white;
  display: inline-flex;
  border: 0;
  text-transform: uppercase;
  &:hover {
    color: $red;
    background-color: $white;
  }
}

.button--white {
  background-color: transparent;
  color: $white;
  display: grid;
  justify-content: center;
  border: 2px solid  $white;
  text-transform: uppercase;
  max-width: 100%;
  flex-wrap: wrap;
  width:20rem;
  margin-bottom: 2rem;
  @include mq(64em) {
   width:20rem;
   
  
  }

  &:hover {
    color: $red;
    background-color: $white;
  }
}

.button--black-addmissions {
  background-color: black;
  color: $white;
  display: grid;
  justify-content: center;
  border: 2px solid  $black;
  text-transform: uppercase;
  max-width: 100%;
  flex-wrap: wrap;
  width:20rem;
  margin-bottom: 2rem;
  @include mq(64em) {
   width:20rem;
   
  
  }

  &:hover {
    color: $black!important;
    background-color: $white;
   
}
}



.button--black-testimonial {
  background-color: black;
  color: white;
  display: grid;
  justify-content: center;
  text-transform: uppercase;
  max-width: 100%;
  flex-wrap: wrap;
  width:16rem;
  margin-bottom: 2rem;
 padding:1.2rem;
  @include mq(64em) {
   width:16rem;
   padding:1.2rem;
  }

  &:hover {
    color: $black;
    background-color: $white;
   
  }
}

.button--white-testimonial{
  background-color: white;
  color: black;
  display: grid;
  justify-content: center;
  text-transform: uppercase;
  max-width: 100%;
  flex-wrap: wrap;
  width:16rem;
  margin-bottom: 2rem;
  padding:1.2rem;
  @include mq(64em) {
   width:16rem;
   padding:1.2rem;
  
  }

  &:hover {
    color: white;
    background-color: black;
  
  }
}

.button__arrow {
  display: block;
  background: $white;
  color: $red-dark;
  width: 3rem;
  margin: -1rem -1rem -1rem 1rem;
  padding: 1.1rem 1rem 1rem;
}



.button--white-hero {
  background-color: white;
  color: black!important;
  display: grid;
  justify-content: center;
  text-transform: uppercase;
  max-width: 100%;
  flex-wrap: wrap;
  width:20rem;
  margin-bottom: 2rem;
  border: none;
  @include mq(64em) {
   width:20rem;
   
  
  }

  &:hover {
    color: $white!important;
    background-color: $black;
  }
}