.cta_heading{
    color: white;
    max-width: 40rem;
    padding-bottom: 1rem;
//    margin-left: -1rem;
padding-top:2rem;

}

.bg-ct-image{
    background-size: cover;
    background-position: center;
    position: relative;

}

.bg-ct-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(87, 19, 14, 0.81) 10.81%, #150407 100%);;
}

.cta-flex{
    display: flex;
    gap:3.5rem;
    align-items: center;
    
}

.cta-flex-wrapper{
    flex-direction: column;
    align-items: center;
    display: flex;
   
    @include mq(64em) {
        display: block;
       }
}