.step-by-step{
    width:100%;
    position: relative;
   
}


// desktop

.step-black{
 
    background-color: black;
    display: none;
    @include mq(64em) {
      
        display: block;
         }
.step-black-inner{
    display: flex;
    color:white;

    padding:5rem 10rem;
    padding-bottom: 20rem;
    gap:4rem;
    h2{
    max-width: 20rem;
    }
    p{
        max-width: 37rem;
        font-size: 1.2rem;
    }
}
}

.step-white{
background-color: white;
display: none;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

@include mq(64em) {
  
  position: absolute;
    display: flex;

    // padding: 16rem 40rem;
    padding:2rem 4rem;
    width:90%;
    // height:600px;
    top:50%;
    left:5%;
     }
}


.step-left-side{
flex-basis: 50%;
ul{
    list-style-type: none;
    position: relative;
    li{
      padding:1.2rem 0;
      font-size: 1.3rem;
      font-weight: 500;
      cursor: pointer;
    }
}
}

.step-left-side ul::before {
  content: '';
  position: absolute;
  top: 1.3rem;
  left: -1rem;
  width: 2px; 
  height: 90%;
  background-color: $red; 
}

.step-right-side{
    max-width:20rem;
    display: grid;
    place-content: center;
    
}

.content-heading{
font-size: 1.5rem;
}

.content-description{
margin-bottom: 6rem;
} 

.content-link{
color:black;
text-decoration: underline;
font-size: 1.1rem;
}

// mobile

.black-background{
    background-color: black;
    color: white;
    padding:4rem 2rem;
    margin:0 auto;
   
    @include mq(64em) {
   
   display: none;
    }
.black-background-inner{
    max-width:40rem;
    margin:0 auto;
}
    h2{

    }
    p{

    }
}



  .step-by-step-accordion{
    padding:0 2rem;
    position: absolute;
   top:90%;
   z-index:3;
   width:100%;
   background-color: transparent;
   @include mq(64em) {
   
    display: none;
     }
  }

.inner-step-accodrdion{

}
.step-accordion {
    background-color:white;
    color: black;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 1.2rem;
    transition: 0.4s;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
 
  }
  
  .step-accordion.active,
  .step-accordion:hover {
    background-color: white;
   border-left: 0.4rem solid $red;
  }
  
  .panel {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
    padding-bottom: 4rem;
   p{
    padding:4rem 0;
   }
        a{
            color:black;
            font-size: 1.1rem;
           
            font-weight: 500;
            
            text-decoration: underline;
        }
    
  }
  

  .step-left-side ul li {
    position: relative;
  }

  .step-left-side ul li:hover {
   font-weight: bold;
   transition: ease-in-out;
  }

  .step-left-side ul li.active::before {
    content: '';
    position: absolute;
    top: 0;
    left: -3.4rem;
    height: 100%;
    border-left: 0.5rem solid $red;
  }
  