.block {
  @include clear;
  @include spacing(1);
}

.block__grid {
  @extend .grid;
  @extend .grid--narrow;
}

.block__grid--top {
  margin-bottom: 0.05rem;
  @include mq(65em) {
    display: flex;
  }
  .block__grid__img {
    object-fit: cover;
    width: 100%;
    @include mq(65em) {
      width: 60%;
    }

  }
}

.block__header__wrap {
  display: inline-block;
}

.block__header {
  @extend .h1;
  display: inline-block;
  margin-bottom: 0.15rem;
  background: $red-dark;
  color: $white;
  padding: 0 0.5rem;
}

.block__grid__text {
  color: $white;
}

.block__grid__content {
  padding: 3rem 2rem 2rem;
  background: $red;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include mq(32em) {
    padding: 4rem 3rem 3rem;
  }
  @include mq(65em) {
    width: 40%;
  }
}


.block__grid__title {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  @include mq($max:32em) {
    padding-right: 1rem;
  }
  @include fluid-type(font-size, $minScreen, $maxScreen, 16px, 22px);
  font-weight: $bold;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: $white;
  z-index: 1;
  @include mq(64em) {
    top: 2.5rem;
    width: 60%;
  }
}

.block__grid__overlay__text {
  visibility: hidden;
  display: none;
  @include mq(48em) {
    display: block;
    visibility: visible;
    position: absolute;
    bottom: 1rem;
    right: 1.5rem;
    color: $white;
    text-transform: uppercase;
    font-weight: $bold;
    letter-spacing: 1px;
    padding: 0.5rem 1rem;
    opacity: 0;
    transition: 500ms $transition;
    transition-delay: 300ms;
    &:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 0.4rem;
      right: 0;
      top: 0;
      background: $white;
    }
  }
}

.block__grid__overlay {
  border-top: 0px solid rgba($red, .7);
  border-left: 0px solid transparent;
  width: 0;
  top: 0;
  right: 0;
  height: 0;
  opacity: .95;
  position: absolute;
  transition:all .5s ease-out;
}

.block__grid__item {
  position: relative;
  @extend .grid__item--narrow;
  background: $white;
  margin-bottom: 0.05rem;
  float: left;
  width: 50%;
  backface-visibility: hidden;
  overflow: hidden;
  &:nth-child(2n+1) {
    clear: both;
  }
  @include mq(64em) {
    width: 33.333%;
    &:nth-child(3n+1) {
      clear: both;
    }
  }
  & + & {
    padding-right: 0.05rem;
  }
  img {
    object-fit: cover;
    width: 100%;
  }
  &:before {
    position: absolute;
    content: '';
    background: rgba($black, .3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &:hover {

    .block__grid__overlay {
      border-top: 1400px solid rgba($red, .8);
      border-left: 1400px solid transparent;
      transition: all .5s ease-out;
    }
    .block__grid__overlay__text {
      opacity: 1;
    }
  }
}
