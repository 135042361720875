
.media__item {
  display: inline-block;
  position: relative;
  overflow: hidden;
  @include mq(48em) {
    display: block;
  }
}

.media__play {
  &:hover {
    .media__icon {
      color: rgba($white, 1);
    }
  }
}
.media__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3rem;
  color: rgba($white, .8);

}
