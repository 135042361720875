.share {
  @include clear;
  @include spacing(3);
  padding-bottom: 1.5rem;
  border-bottom: .6rem solid $gray-lighter;
}

.share__heading {
  font-weight: $reg;
  @include font-size(14);
}

.share__list {
  @include clean;
  list-style: none;
}

.share__list__item {
  display: inline-block;
  margin: 1rem 0;
}

.share__item__link {
  padding: 1rem;
  border: .15rem solid $red;
  &:hover {
    background: $red;
    .share__link__icon {
      color: $white;
    }
  }
}

.share__link__icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
  color: $red;
}
