.subnav_heading{
    color: white;
    // padding:2rem 0;
    max-width: 40rem;
   margin-left: 1rem;
}


.subnav-variation {
    display: flex;
    align-items: center;
    gap:4rem;
    
  }

  .margin-subnav-devider{
    margin-top:2rem;
  }
  
  .subnav-variation-dropdown {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: clamp(0.75rem,0.68rem + 0.37vw,0.9375rem);
    transition: border-bottom-width 0.3s ease-out;
    text-align: left;
    cursor: pointer;
    @include mq(68em) {
      width: 20rem;
      margin-bottom: 0.5rem;
       }
  }

  .subnav-variation-dropdown-alt{
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: clamp(0.75rem,0.68rem + 0.37vw,0.9375rem);
    transition: border-bottom-width 0.3s ease-out;
    text-align: left;
    cursor: pointer;
    @include mq(68em) {
      width: 20rem;
      margin-bottom: 0.5rem;
       }
  }

  .subnav-variation-dropdown-alt:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0.9rem;
    width: 100%;
    height: 2px;
    background-color: rgb(0, 0, 0);
    transition: width 0.3s ease-out;
    transition: width 0.3s ease-out;
  cursor: pointer;
  }



  .subnav-variation-dropdown:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0.9rem;
    width: 100%;
    height: 2px;
    background-color: white;
    transition: width 0.3s ease-out;
    transition: width 0.3s ease-out;
  cursor: pointer;
  }
  
  .subnav-variation-dropdown:hover:after {
    height: 4px;
    cursor: pointer;
  }

  .subnav-variation-dropdown-alt:hover:after {
    height: 4px;
    cursor: pointer;
  }
  
  .subnav-variation-dropdown__toggle {
    // font-weight: 600;
    color: white;
    position: relative;
    z-index: 1;
 
  }

  .subnav-variation-dropdown__toggle-alt {
    // font-weight: 600;
    color: black;
    position: relative;
    z-index: 1;
 
  }
  
  .subnav-variation-dropdown__icon {
    display: none;
  }

  .subnav-variation-dropdown__icon-alt {
    display: none;
  } 

  .subnav-variation-dropdown__icon-desktop {
    opacity: 0;
    position: absolute;
    right:-0.5rem;
   
  }

  .subnav-variation-dropdown__icon-alt-desktop{
    opacity: 0;
    position: absolute;
    right:-0.5rem;
  }


  .subnav-variation-dropdown:hover > .subnav-variation-dropdown__icon-desktop {
    opacity: 1;
  } 

 
  .subnav-variation-dropdown-alt:hover > .subnav-variation-dropdown__icon-alt-desktop {
    opacity: 1;
  } 
  
  /* For tablet and mobile */
  @media only screen and (max-width: 991px) {
    .subnav-variation-dropdown__icon-desktop {
      display: none;
    }
    .subnav-variation-dropdown__icon-alt-desktop{
      display: none;
    }
    .subnav-variation {
      display: block;
    //   padding: 0 20px;
    }

    .margin-subnav-devider{
      margin-top:0;
    }
  
    .subnav-variation-dropdown {
      width: 100%;
      padding-right: 40px;
      box-sizing: border-box;
    }
  
    .subnav-variation-dropdown__toggle {
    //   font-size: 16px;
    //   padding: 20px 0;
      text-align: right;
    }
  
    .subnav-variation-dropdown__toggle:hover {
      color: white;
      text-decoration-color: white;
    }
  
    .subnav-variation-dropdown__toggle::after {
      bottom: 0;
      transform-origin: bottom;
    }

    .subnav-variation-dropdown__toggle-alt {
      //   font-size: 16px;
      //   padding: 20px 0;
        text-align: right;
      }
    
      .subnav-variation-dropdown__toggle-alt:hover {
        color: black;
        text-decoration-color: black;
      }
    
      .subnav-variation-dropdown__toggle-alt::after {
        bottom: 0;
        transform-origin: bottom;
      }
  
    .subnav-variation-dropdown__icon {
      display: inline-block;
      position: absolute;
      right: 20px;
      top: calc(50% - 10px);
      width: 15px;
      height: 15px;
      fill: white;
      z-index: 1;
      transition: all 0.3s ease-out;
    }

    .subnav-variation-dropdown__icon-alt {
      display: inline-block;
      position: absolute;
      right: 20px;
      top: calc(50% - 10px);
      width: 15px;
      height: 15px;
      fill: black;
      z-index: 1;
      transition: all 0.3s ease-out;
    }
  
    .subnav-variation-dropdown__toggle:hover {
      font-weight: bold;
    }

    .subnav-variation-dropdown__toggle-alt:hover {
      font-weight: bold;
    }


    .subnav_heading{
      
       margin-left: 0;
    }



    .subnav-variation-dropdown:after,
    .subnav-variation-dropdown:hover:after {
      display: none;
    }

    .subnav-variation-dropdown-alt:after,
    .subnav-variation-dropdown-alt:hover:after {
      display: none;
    }
  }




 