.feature {
  @include clear;
  @include spacing(2);
  background: $gray-lighter;
}

.feature__img {
  float: left;
  @include mq(48em) {
    max-width: 45%;
  }
}

.feature__content {
  padding: 2rem;
  overflow: hidden;
}

//

.feature--admissions {
  background-color: $gray-lighter;
  position: relative;
  display: table;
  @include mq($max:64em) {
    padding: 2rem 1rem;
  }
}

.feature--admissions__content {
  float: left;
  display: table-cell;
  @include mq(64em) {
    border-left: 0.5rem solid $red;
    width: 60%;
    margin: 4rem 0;
    padding: 0 6rem;
  }
  @include mq(90em) {
    padding: 0 8rem;
  }
  p:last-child {
    @include mq(64em) {
      margin-bottom: -0.5rem;
    }
  }
}

.feature--admissions__img {

  @include mq(64em) {
    max-width: 40%;
    margin-right: 0;
    margin-left: auto;
  }
  @include mq(100em) {
    max-width: auto;
  }
}

.feature--admissions__content__header {
  @include mq(64em) {
    margin-top: -1rem;
  }
}

//

.feature__block {
  padding: 2rem 1.5rem;
}

.feature__block__grid__wrap {
  margin: 1rem 0 3rem;
  hr {
    display: none;
    @include mq(48em) {
      display: block;
    }
  }
}

.feature__block__grid {
  display: flex;
  flex-flow: row wrap;
  padding: 0.25rem 0;
}


.feature__block__grid__item {
  text-align: center;
  border-bottom: 0.1rem solid $black;
  padding: 1rem;
  width: 100%;
  @include mq(48em) {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    float: left;
    width: 50%;
    border-bottom: 0;
    &:nth-child(2n+1) {
      clear: both;
    }
  }
  &:first-child {
    @include mq(48em) {
      border-right: 0.1rem solid $black;
    }

  }
  .p--small {
    margin-bottom: 0.25rem;
  }
  .p--serif {
    @include font-size(24);
    font-weight: $bold;
    margin: 0;
  }
}

//

.feature__icon {

  width: 4rem;
  height: 4rem;
  display: block;
  background-size: 100% 100%;
  margin: 0 auto 1rem auto;
}

.feature__icon--person {
  @include fill($svgstore--Icon_Person_SpeechBubble, $white);
}

.feature__icon--arrow {
  @include fill($svgstore--Icon-Arrow, $black);
}

.feature__divider {
  height: 0.4rem;
  background: $red;
  width: 22rem;
  margin: 1.5rem auto;
}

.feature__text__block {

  margin: 2rem auto;
  @include mq(48em) {
    width: 50%;
  }
}

//

.feature__hero {
  position: relative;
  background-size: cover;
  background-position: center;
  display: block;
  overflow: hidden;
  &:before {
    position: absolute;
    content: '';
    background-color: rgba($black, .3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.feature__hero__video {
  position: relative;
  background-size: cover;
  background-position: 50%;
}

.feature__hero__video__media {
  position: absolute;
  min-width: 100%;
  min-height: auto;
  @include mq(48em) {
    min-height: 51rem;
  }
}


.feature__hero__triangle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid $white;
}

.feature__hero__content {
  position: relative;
  color: $white;
  padding: 5rem 0;
  text-align: center;
  @include mq(64em) {
    padding: 10rem 0;
  }
  p {
    font-weight: $bold;
    @include font-size(18);
    margin-bottom: 2rem;
  }
}

.feature__hero__svg {
  @include fill($svgstore--Clarksville_TN, $white);
  display: block;
  height: 10rem;
  width: 100%;
  background-size: auto 60%;
  transform: translateX(15%);
  @include mq(32em) {
    background-size: auto 80%;
    transform: translateX(25%);
  }
}

