/* Router Panel styles */

.router-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .image-wrapper {
    flex: 1 1 500px;
    margin-right: 2rem;
  }
  
  .image-wrapper img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  
  .router-content {
    flex: 1 1 400px;
  }
  
  .router-content h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 2.2rem;
  }
  
  .router-content p {
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 2rem;
  }
  
  .router-variation-dropdown {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .router-variation-dropdown a {
    display: block;
    font-size: 1.2rem;
    color: $red!important;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    font-weight: 500;
   padding:1rem;
  }

  .router-variation-dropdown:hover {
//  color:red!important;
//  background: linear-gradient(currentColor, green);
    
//  color: #f00;
  }

  .router-variation-link{
   border-bottom: 1px solid $red;
   padding-bottom: 0.3rem;
   transition: all 50ms ease-in;
  }


  .router-variation-link:hover{
    border-bottom: 3px solid $red;
   }
   
  
  
 
  
  .router-variation-dropdown__icon {
    display: none;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    fill: red!important;
  }

  .router-variation-dropdown__icon-desktop{
    opacity: 0;
    position: absolute;
    right:0.5rem;
    // transition: all 250ms ease-in-out;
  }



  .router-variation-dropdown:hover > .router-variation-dropdown__icon-desktop {
    opacity: 1;
  } 
  


 
  
  @media only screen and (max-width: 768px) {

    .router-variation-dropdown__icon-desktop{
      display: none;
    }
  
   
    .router-flex {
      display: block;
    }
  
    .router-content h2 {
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: 1.8rem !important;
    }
  
    .image-wrapper {
      margin-right: 0!important;
      margin-bottom: 0!important;
      width:100%;
    }
  
    .router-content {
      margin-bottom: 2rem;
    }
  
    .router-variation-dropdown__icon {
      display: inline-block;
    }

  
    
    .router-variation-dropdown__icon svg{
       fill: $red !important;
      }
  
    
    
    .router-variation-dropdown a {
      padding: 1rem 1rem 0.5rem 1rem;
      margin-bottom: 0.5rem;
      background-image: none!important;
    }
  }
  

