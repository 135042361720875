.news {
  @include clear;
  @include spacing(1);
}

.news__item {
  // display: table;
  margin-bottom: 0.15rem;

  @include mq(48em) {
    margin-bottom: 0;
  }
}

.news__item--alt {
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,.15);
}

.news__img {
  float: left;
  .news__item--horizontal & {
    @include mq(48em) {
      max-width: 40%;
      object-fit: cover;
    }
  }
}

.news__item--horizontal {
  margin-bottom: 0.15rem;
}

.news__content {
  background: $gray-lighter;
  padding: 2rem 1.5rem;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  .h5 {
    color: inherit;
    display: inline-block;
    &:hover {
      color: $red;
    }
  }
  .news__item--img & {
    display: block;
  }
}

.news__content--alt {
  background: $white;
}

.news__studnet__name {
  @include font-size(24);
  font-weight: $bold;
  color: $red;
  margin-bottom: 0.25rem;
  display: block;
  &:hover {
    color: $gray;
  }
}

.news__student__major {
  @include font-size(15);
  font-weight: $bold;
  margin-bottom: 1rem;
}
