// Font sizing
@mixin font-size($sizeValue: 16) {
  font-size: ($sizeValue / 16) + rem;
}

// Fluid type
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}


// clearfix
@mixin clear {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  }
}

// underline
@mixin underline($color: currentColor, $position: 90%, $size: 0.0625rem) {
  background-image: linear-gradient($color, $color);
  background-repeat: repeat-x;
  background-position: 0 $position;
  background-size: 100% $size;
}


// Clear margin and padding
@mixin clean {
  margin: 0;
  padding: 0;
}

// hide
@mixin hide {
  position: absolute;
  left: -999rem;
}

// show
@mixin show {
  position: static;
  left: auto;
}

@mixin sr-hide {
  position: absolute;
  left: -999rem;
  visibility: hidden;
}

@mixin sr-show {
  position: static;
  left: auto;
  visibility: visible;
}

// min-width media query
@mixin mq($min: 0, $max: 0) {
  @if ($min > 0 and $max > 0) {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
  @else if ($min > 0) {
    @media (min-width: $min) {
      @content;
    }
  }
  @else if ($max > 0) {
    @media (max-width: $max) {
      @content;
    }
  }
}

// svg color fill
@mixin fill($svg, $color) {
  $color: str-slice(#{$color}, 2);
  $insert: 'fill%3D%22%23#{$color}%22%20';
  $index: str-index($svg, '%3Csvg%20') + 9;
  background-image: str-insert($svg, $insert, $index);
}

// spacing
@mixin spacing($x: 1) {
  margin-top: 1.25rem * $x;
  margin-bottom: 1.25rem * $x;
}
