.swiper-container {
  width: 100%;
  /* height: 300px; */

}

.swiper-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  font-size: 24px; 
  // background-color: $red;
}

.testimonial-slide{
  background-color: $red;
}


.swiper-container [class^="swiper-button-next"]::after{
  display: flex;
  content: url("data:image/svg+xml,%3Csvg  width='25' height='30' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.89802 0.857422L0.927734 3.82771L5.87822 8.77819L0.927734 13.7287L3.89802 16.699L11.8188 8.77819L3.89802 0.857422Z' fill='black'/%3E%3C/svg%3E")
}

.swiper-container [class^="swiper-button-prev"]::after{

  transform: rotateY(180deg);
  content: url("data:image/svg+xml,%3Csvg width='25' height='30' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.89802 0.857422L0.927734 3.82771L5.87822 8.77819L0.927734 13.7287L3.89802 16.699L11.8188 8.77819L3.89802 0.857422Z' fill='white'/%3E%3C/svg%3E")
}


.swiper-container [class^="swiper-button-prev"]:hover::after {

  content: url("data:image/svg+xml,%3Csvg width='25' height='30' viewBox='0 0 12 17' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.89802 0.857422L0.927734 3.82771L5.87822 8.77819L0.927734 13.7287L3.89802 16.699L11.8188 8.77819L3.89802 0.857422Z' fill='black'/%3E%3C/svg%3E")

}

.swiper-container [class^="swiper-button-next"]:hover::after {
    
  content: url("data:image/svg+xml,%3Csvg width='25' height='30' viewBox='0 0 12 17' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.89802 0.857422L0.927734 3.82771L5.87822 8.77819L0.927734 13.7287L3.89802 16.699L11.8188 8.77819L3.89802 0.857422Z' fill='white'/%3E%3C/svg%3E")

}



.swiper-button-next:hover{
  background-color: black!important;
 }

 .swiper-button-prev:hover{
   background-color: white!important;
 }
 

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  bottom: 1rem;
  top: 10rem;
  transform: translateY(-50%);
  // width: 20px!important;
  // height: 20px!important;

  padding: 1.7rem;
  display: inline-flex !important;
  align-items: center!important;
  font-size: 17px;
  color: #000;
  cursor: pointer;
  z-index: 10;
  transition: ease-in-out 350ms;

@include mq(64em) {
  display: inline-flex;
  align-items: center;
  padding: 2rem;
}
}

.swiper-button-prev{

  background-color: black;

}

.swiper-button-next{

  background-color: white;

}



.button-group-testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq(64em) {
    flex-direction: row;
    gap: 1rem;
  }
}

.testimonial {
  display: flex;
  flex-direction: column;
  @include mq(65em) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .testimonial-text {
    margin-bottom: 5rem;
    color: white;
    text-align: center;
    @include mq(65em) {
      text-align: left;
    }
    p {
      font-size: 1.7rem;
      font-family: "Adobe Garamond Pro";
      text-align: center;
      @include mq(65em) {
        font-size: 2.3rem;
        text-align: left;
      }
    }
    span {
      font-size: 1.1rem;
    }
  }
}

.content-testimonial {
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: $red;
  @include mq(65em) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.content-testimonial-inner {
  max-width: 33rem;
  z-index: 4;
  padding: 2rem 1rem;
  @include mq(65em) {
    padding: 0 1rem;
  }
}


.container-feature-image-testimonial {
  position: relative;
  display: contents;
  @include mq(65em) {
    display: block;
  }
}

  
  .slanted-testimonial{
    position: absolute;
    left:-6.5rem;
    top: 0;
     width: 10rem;
     height: 100%;
    background-color: $red;
    border-right:0.7rem solid white;
     transform: skewX(10deg);
     z-index: 1;
     display: none;
     @include mq(65em) {
     display: block;
        }
  }
  




  .swiper-pagination {
    position: absolute;
    bottom: 2.5rem;
    // left: 40%;
    // transform: translateX(-40%);
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;
     left: 41%;
     border-radius: 5px;
     text-align: center;
  transform: translateX(-41%);
    @include mq(64em) {
      left: 50%;
      transform: translateX(-50%);
    }
    

    @include mq(64em) {
      left: 50%;
      transform: translateX(-50%);
      padding: 5px 3px  5px 15px; 
    }
    background-color: rgba(0, 0, 0, 0.5);
 
    padding: 5px 19px  5px 15px; 
  }
  
  .swiper-pagination-bullet {
    width: 1rem!important;
    height: 1rem!important;
    background-color: #e5e2e2!important;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid black!important;

    @include mq(64em) {
      left: 50%;
      
    transform: translateX(-50%);
     }
  }
  

  .swiper-pagination-bullet-active {
    background-color: white!important;
    @include mq(64em) {
      background-color: white!important;
     }
  }



  .desktop-arrows{
    display: none;
 

    @include mq(64em) {
      display: block;
z-index:4;
position: absolute;
top:15rem;
right:1rem;

   
    
      .swiper-button-next{
        position: static;
        background-color: white;
        margin-bottom: 6.8rem;
     
      }
      
     }
  }

  .desktop-arrows .swiper-button-prev{
    // left: 55rem !important;
    position: absolute;
    background-color: black;
    margin-left: -0.6rem;
    
  }
  

  .mobile-arrows{
    display: block;
    margin-top: 3.8rem;
    position: relative;
    padding:2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .swiper-button-next::after{
      content: url("data:image/svg+xml,%3Csvg  width='25' height='30' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.89802 0.857422L0.927734 3.82771L5.87822 8.77819L0.927734 13.7287L3.89802 16.699L11.8188 8.77819L3.89802 0.857422Z' fill='white'/%3E%3C/svg%3E")
     
       }

       .swiper-container [class^="swiper-button-next"]:hover::after {
        content: url("data:image/svg+xml,%3Csvg width='25' height='30' viewBox='0 0 12 17' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.89802 0.857422L0.927734 3.82771L5.87822 8.77819L0.927734 13.7287L3.89802 16.699L11.8188 8.77819L3.89802 0.857422Z' fill='black'/%3E%3C/svg%3E")
        
      }
      .swiper-container [class^="swiper-button-prev"]::after{
        position: absolute;
        top:10%!important;
        transform: rotateY(180deg);
        content: url("data:image/svg+xml,%3Csvg width='25' height='30' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.89802 0.857422L0.927734 3.82771L5.87822 8.77819L0.927734 13.7287L3.89802 16.699L11.8188 8.77819L3.89802 0.857422Z' fill='white'/%3E%3C/svg%3E")
      }
      
      
      .swiper-container [class^="swiper-button-prev"]:hover::after {
      
        content: url("data:image/svg+xml,%3Csvg width='25' height='30' viewBox='0 0 12 17' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.89802 0.857422L0.927734 3.82771L5.87822 8.77819L0.927734 13.7287L3.89802 16.699L11.8188 8.77819L3.89802 0.857422Z' fill='black'/%3E%3C/svg%3E")
      
      }

      .swiper-button-next{
        background-color: $red;
        border-radius: 50%;
        
         }
     
      .swiper-button-prev:hover{
      opacity: 0.8;
      border: 1px solid black;

      }

    .swiper-button-prev{
      border-radius: 50%;
    
    }
    @include mq(64em) {
      display: none;
     }
   
  }


