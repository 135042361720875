

.feed__item {
  @include clear;
  @include spacing(1);
  border-bottom: 0.1rem solid $black;
  padding-bottom: 2rem;
  & + & {
    padding: 2rem 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.feed__img {
  margin-bottom: 1rem;
  @include mq(48em) {
    float: left;
    margin-right: 1rem;
  }
}

.feed__content {
  overflow: hidden;
}


.feed__content__link {
  display: block;
  @extend .h5;
  @extend .header--snuggle-half;
  color: $black;
  &:hover {
    color: $red;
  }
}
