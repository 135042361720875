.nav,
.nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.nav {
	width: 100%;
  @include mq(64em) {
    background: $black;
    width: auto;
    height: 5rem;
    float: right;
    @include fluid-type(margin-right, 1024px, 1440px, 130px, 160px);
  }
}

.nav ul {
	// left: -9999rem;
	background: $gray-dark;
	position: absolute;
	z-index: 1;
  @include mq(64em) {
    background: $black;
  }
}

.nav li {

  margin-bottom: 0;
  border-top: .1rem solid $red;
  &:last-child {
    border-bottom: .1rem solid $red;
    @include mq(64em) {
      border: 0;
    }
  }
  @include mq(64em) {
    position: relative;
    border: 0;
    float: left;
    padding: 0 0.5rem;
    @include fluid-type(font-size, 1024px, 1440px, 13px, 16px);
  }
  @include mq(80em) {
    padding: 0 1.25rem;
  }
}

.nav li > a {
  width: calc(100% - 3.5rem);
  color: $white;
  padding: 1.25rem;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  background: none;
  font-weight: $reg;
  @include mq(64em) {
    padding: 0;
    width: auto;
    text-align: center;
    line-height: 5rem;
    display: block;
  }
}


.nav li > a:hover,
.nav li > a:focus,
.nav li:focus > a,
.nav li:hover > a {
  background-image: none;
  color: $white;
  @include mq(64em) {
    background: $black;
    color: $white;
    @include underline($position: 75%, $color: $red, $size: 0.15rem);
  }
}


// .nav ul li {
//   @include mq(64em) {
//       width: 100%;
//   float: none;
//   position: static;
//   }

// }

.nav ul li:hover,
.nav ul li:focus {
  a {
    background-image: none;
  }
}

.nav ul a {
	float: none;
	display: block;
	padding: 1rem;
  background: $gray-dark;
  text-align: left;

  @include mq(64em) {
    text-transform: none;
    padding: 0.5rem 0;
    background: $black;
    line-height: $baseline;
  }
}

.nav ul a:hover,
.nav ul a:focus {
  @include mq(64em) {
    color: $white;
    background: $black;

    text-decoration: underline;
  }
}

.nav li:hover ul,
.nav li:focus ul,
.nav ul.show-menu {
  @include mq(64em) {
    position: absolute;
    left: 0;
    top: 4.4rem;
    width: 175%;
    padding-bottom: 1rem;
    margin-left: -1rem;
    padding: 0 1rem 1rem 1rem;
    height: auto !important;
    z-index: 2;
    display: block;
  }
}

.nav .menu {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: calc(100vh - 3.5rem);
  overflow: auto;
  z-index: 2;
  @include mq(64em) {
    display: none;
  }
}

// li.expanded {
//   position: static;
// }

.expanded > .menu {
  transform: translateX(-100%);
  a {
    width: 100%;
  }
  @include mq(64em) {
    width: auto;
    transform: none;
    opacity: 1;
    visibility: visible;
  }
}

