.accordion {
  @include spacing(2);
}

.accordion__toggle {
  padding: 1rem 0 1rem 1rem;
  background: $gray-lighter;
  margin: 0.15rem 0;
}


.accordion__content {
  padding: 0 0 1rem;
}

.accordion__toggle {
  display: block;
  width: 100%;
  text-align: left;
  @include font-size(15);
  color: $black;
  &:hover {
    text-decoration: none;
    color: $black;

  }
}

.accordion__icon {
  width: 1.5rem;
  margin-right: 1.25rem;
  padding: 0.125rem;
  float: right;
  color: $black;
}

.accordion__icon--inactive {
  display: block;
  .accordion__toggle--active & {
    display: none;
  }
}

.accordion__icon--active {
  display: none;
  .accordion__toggle--active & {
    display: block;
  }
}

.accordion__content {
  @include sr-hide;
}

.accordion__toggle--active {
  border-bottom: 0;
}

.accordion__content--active {
  @include sr-show;
  margin: 2rem 2rem 2rem 1rem;
  border-left: .15rem solid $red;
  padding-left: 2rem;
  p {
    margin-bottom: 0;
  }
}
