.visual-grid-container{
    margin-top:3rem;
}

.visual-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    // padding: 20px;
  }
  
  .visual-grid-content {
    flex-basis: 100%;
  
  }
  
  .grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 20px;
  }

  .grid-h3{
    margin-top:1rem !important;
  }

  .grid-h3:hover{
    color: black;
  }
  
  .card-link {
    flex-basis: calc(33.33% - 20px);
    margin-bottom: 20px;
    p{
        color: black;
        font-weight: 350;
        font-size: 1rem;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  }
}

  .divider{
    border: 1px solid #93939355;
    margin-top:2rem;
    margin-bottom: 1rem;

  }

  .frame{
   
    img{
      width:100%
    }
  }

  
  
  @media screen and (max-width: 767px) {
    .card-link {
      flex-basis: 100%;
    }
  }
