.quote-slide,
.quote-slide-js {
  position: relative;
  &:before,
  &:after {
    @include mq(64em) {
      background: rgba(#91182E, .5);
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      width: 240px;
      display: block;
    }
  }
  &:before {
    clip-path: polygon(0 0, 60% 0%, 100% 100%, 0 100%);
    left: 0;
  }
  &:after {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 40% 100%);
    right: 0;
  }
  .slick-list {
    overflow: visible;
  }
}



.quote-slide__item {
  position: relative;
  padding: 6rem 2rem;
  opacity: .3;
  transition: opacity $transition;
  .quote-slide & {
    opacity: 1;
  }
  @include mq(64em) {
    padding: 8rem 4rem;
    .quote-slide & {
      padding: 8rem 4rem 12rem;
      opacity: 1;
      width: calc(100% - 26rem);
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.quote-slide__item.slick-current {
  opacity: 1;
  &:before {
    background: transparent;
  }
}

.quote-slide__img {
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 2rem;

  @include mq(80em) {
    margin-right: 1.5rem;
    float: left;
  }
  .quote-slide--white & {
    margin-right: 1.5rem;
    float: left;
    max-width: 50%;
    @include mq(48em) {
      max-width: 100%;
    }
  }
}

.quote-slide__content {
  overflow: hidden;
}

.quote-slide__content__tag {
  @include font-size(15);
  font-weight: $reg;
  text-transform: uppercase;
}

.quote-slide__content__title {
  @include font-size(30);
  font-weight: $reg;
  margin-bottom: 1.5rem;
}

.quote-slide__quote {
  display: block;
  font-family: $serif;
  @include font-size(32);
  margin-top: 1.5rem;
  margin-left: 0.5em;
  text-indent: -0.35em;
  .quote-slide--white & {
    margin-right: 1rem;
  }

}

.quote__slider__nav {
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  @include mq(80em) {
    bottom: 20%;
  }

  .slider__nav--left,
  .slider__nav--right {
    width: 3rem;
    height: 3rem;
    background-size: auto 40%;
  }
}

//

.quote-slide--white {
  position: relative;
  @include spacing(4);
  border-top: 0.5rem solid $gray-lighter;
  border-bottom: 0.5rem solid $gray-lighter;
}
.quote-slide__white__item {
  @include clear;
  padding: 3rem 0;
  @include mq(64em) {
    padding: 3rem 0 6rem;
  }

}

.quote-slide__name {
  @include font-size(18);
  font-weight: $reg;
}

.quote-slide__year {
  @include font-size(14);
  font-weight: $reg;
  margin-bottom: 1.5rem;
}

.quote-slide__white__content {
  overflow: hidden;
  float: left;
  @include mq(64em) {
    float: none;
  }
}

.quote__slider__nav--white {
  position: absolute;
  top: 35%;
  right: 0;
  @include mq(32em) {
    top: 30%;
  }
  @include mq(64em) {
    top: auto;
    bottom: 2rem;
    right: calc(100% - 340px);
  }

  .slider__nav--left,
  .slider__nav--right {
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: .1rem solid $red;
    color: $red;
    background-position: center;
    &:hover,
    &:focus {
      background: $red;
    }
  }
  .slider__nav--right {
    @include fill($svgstore--Arrow_Small_Right, $red);
    background-repeat: no-repeat;
    margin-left: 0.25rem;
    &:hover,
    &:focus {
      @include fill($svgstore--Arrow_Small_Right, $white);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .slider__nav--left {
    @include fill($svgstore--Arrow_Small_Left, $red);
    background-repeat: no-repeat;
    &:hover,
    &:focus {
      @include fill($svgstore--Arrow_Small_Left, $white);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

//

.quote-slide__paragraph {
  margin-top: 1.5rem;
  @include fluid-type(font-size, $minScreen, $maxScreen, 16px, 20px);
}

.quote-slide__heading {
  text-align: center;
  margin-bottom: -4rem;
  padding-top: 4rem;
}
