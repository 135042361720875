.alert-item-admission{
    position: relative;
}


.alert-fixed-alt {
    position: static;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 3rem 3rem;
    background-color:#497A7B;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    @include mq(64em) {
    flex-direction: row;
    width:100%;
    }
  }
  
  .alert-fixed-alt .close {
    position: absolute;
    top: 2rem;
    right: 1.5rem;
    color: inherit;
    z-index: 2;
    width: 1rem;
    @include mq(64em) {
      position: static;
     width: 1.2rem;
      }
  }
  

  .alert-text-wrapper-alt{
    max-width:35rem;
  }


  .admission-text{
    display: grid;
    place-content: center;
    color: white;
    padding-bottom: 1rem;
//    margin-left: -1rem;
padding-top:2rem;
  }

  .admission-cta{
    color: white;
    text-align: center;
    padding:0.2rem 1rem;
    font-size: 1.5rem;
    @include mq(64em) {
      padding:0.2rem 15rem;
      }
  }

  .admission-margin{
    margin-top: 1rem;
    
    @include mq(64em) {
        margin-top: 4rem;
        justify-content: center;
        }
  }


  .admission-text-item{
    display: grid;
    place-content: center;
p{
    font-size: 1.2rem;
    a{
 color: black;
 font-weight: 600;
    }
    strong{

    }
}
  }