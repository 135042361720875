.menu-active {
  overflow: hidden;
}

.header {
  .menu-active & {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1012;
  }
  @include mq(64em) {
    position: static;

  }
}


.header__wrap {
  position: relative;
}

.header__small {
  background: $black;
  color: $white;
  border-left: .4rem solid $red;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 4.9rem;
  @include mq(64em) {
    display: none;
  }
}

.header__small__logo {
  width: 20rem;
  margin-right: auto;
  color: $white;
  padding: 1rem;
  @include mq(24em) {
    padding: 1rem 7rem 1rem 1rem;
  }
}

.header__small__menu-toggle {
  width: 3.5rem;
  padding: 1rem;
  height: 100%;
}

.header__small__menu-toggle__open {
  display: block;
  .menu-active & {
    display: none;
  }
}

.header__small__menu-toggle__close {
  display: none;
  .menu-active & {
    display: block;
  }
}

.header__small__search-toggle {
  width: 3.5rem;
  padding: 1rem;
  border-left: 0.0625rem solid $white;
  height: 100%;
}

.header__small__search-toggle__open {
  display: block;
  .search-active & {
    display: none;
  }
}

.header__small__search-toggle__close {
  display: none;
  .search-active & {
    display: block;
  }
}

.header__small__back {
  @include clear;
  height: 4.9rem;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  width: calc(100% - 6.5rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: $red;
  font-size: 1.125rem;
  padding: 0.875rem 1rem 0.875rem 0.5rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition, visibility $transition;
  color: $white;
  .menu-active .header--back & {
    opacity: 1;
    visibility: visible;
  }
}

.header__small__back__icon {
  float: left;
  width: 1.5rem;
  margin-right: 0.5rem;
  padding: 0.25rem;

}
.header__small__back__text {
  @include font-size(16);
}

.header__logo {
  display: none;
  @include mq(64em) {
    display: block;
    height: 5rem;
    background: $black;
    float: left;
    border-left: .3rem solid $red;
  }
}

.header__logo--icon {
  @include fill($svgstore--APSU_Logo, $white);
  display: block;
  width: 15rem;
  height: 5rem;
  background-size: auto 50%;
  background-position: 50% 50%;
}

.header__scroll {
  height: 100%;
  overflow: auto;
  @include mq(64em) {
    overflow: visible;
  }
}



.header__menu {
  position: absolute;
  top: 4.9rem;
  left: 100%;
  width: 100%;
  left: 100%;
  width: 100%;
  height: 100vh;
  background: $gray-dark;
  color: $white;
  overflow: hidden;
  transition: transform $transition;
  @include mq(64em) {
    background: $black;
    height: 5rem;
    position: static;
    border: 0;
    color: $gray;
    overflow: visible;
    transition: none;
  }
  .menu-active & {
    transform: translateX(-100%);
    @include mq(64em) {
      transform: none;
    }
  }
}


.header__nav__toggle {
  display: block;
  width: 3.5rem !important;
  height: 3.5rem;
  color: $white;
  padding: 1.25rem 1.4rem!important;
  float: right;
  @include mq(64em) {
    display: none !important;
  }
  + a {
    overflow: hidden;
  }
  &:before {
    position: absolute;
  }
}

.header__nav__cta {
  @include mq(64em) {
    position: absolute;
    right: 0;
    top: 5rem;
    background: rgba($black, .7);
    padding: 1.5rem 2rem;
    z-index: 4;
  }
}

.nav__cta__list {
  @include clean;
  list-style: none;
  margin: 1rem;
  @include mq(64em) {
    margin: 0;
  }
}

.nav__cta__item {
  margin-bottom: 0;
  padding: 0.5rem;
  @include mq(64em) {
    display: inline-block;
    padding: 0;
    & + & {
      padding-left: 2rem;
    }
  }
}

.nav__cta__link {
  color: $white;
  @include font-size(15);
  font-weight: $bold;
  @include mq(64em) {
    text-transform: uppercase;
    &:hover {
      @include underline($position: 100%);
    }
  }
}

.header__nav__secondary {
  height: 60%;
  @include mq(64em) {
    display: block;
    background: $red;
    position: absolute;
    right: 0;
    top: 0;
    height: 5rem;
    line-height: 5rem;
    padding: 0 0.5rem;
  }
  @include mq(80em) {
    padding: 0 1.5rem;
  }
}

.header__nav__info__list--mobile {
  @include mq(64em) {
    display: none;
  }
}

.header__nav__info__wrap {
  display: none;
  @include mq(64em) {
    display: block;
    position: absolute;
    background: $red;
    top: 0;
    right: 0;
    left: 100%;
    width: calc(100% - 15rem);
    height: 5rem;
    line-height: 5rem;
    z-index: 1001;
    text-align: right;
    transform: translateX(100%);
    transition: transform $transition;
    visibility: hidden;
    .info-active & {
      visibility: visible;
      transform: translateX(-100%);
      padding-right: 3rem;
    }
  }
}

.header__nav__info {
  background: #3B464F;
  display: block;
  width: 100%;
  padding: 1rem;
  text-align: left;
  cursor: default;
  @include mq(64em) {
    cursor: pointer;
    background: transparent;
    width: auto;
    padding: 0;
    display: inline-block;
  }
}

.header__nav__info__icon {
  display: inline-block;
  float: left;
  @include mq(64em) {
    float: none;
  }
}

.header__nav__info__text {
  display: inline-block;
  @include font-size(15);
  font-weight: $reg;
  @include mq(64em) {
    display: none;
  }
}

.header__nav__info__list {
  padding-left: 0;
  @include mq(64em) {
    @include clean;
    display: inline-block;
    vertical-align: middle;
  }
}

.header__nav__info__item {
  margin-bottom: 0;
  @include mq(64em) {
    display: inline-block;
    float: left;
  }
}

.header__nav__info__link {
  @include font-size(15);
  font-weight: $bold;
  color: $white;
  display: block;
  padding: 0.5rem 1.5rem;
  @include mq(64em) {
    display: inline;
    @include fluid-type(font-size, 1024px, 1440px, 13px, 16px);
    @include fluid-type(padding-right, 1024px, 1440px, 5px, 25px);
    @include fluid-type(padding-left, 1024px, 1440px, 5px, 25px);
    text-transform: uppercase;
    font-weight: $reg;
    &:hover {
      text-decoration: underline;
    }
  }
}

.header__nav__info__close {
  display: none;
  @include mq(64em) {
    display: inline-block;
    color: $white;
    width: 1rem;
    vertical-align: middle;
  }
}

.header__nav__search {
  display: none;
  @include mq(64em) {
    display: inline-block;
  }
}

.header__nav__info__icon,
.header__nav__search__icon {
  display: inline-block;
  width: 3rem;
  color: $white;
  padding: 0 0.8rem;
  vertical-align: middle;
}

.header__nav__info__icon {
  // .search-active & {
  //   display: none;
  // }
}


.header__nav__info--open {
  display: block;
}

.header__nav__search--open {
  display: block;
  .search-active & {
    display: none;
  }
}

.header__nav__search--close {
  display: none;
  .search-active & {
    display: block;
  }
}
