table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  thead th, tfoot th {
    font-weight: normal;
  }
  thead {
    th, td {
      padding: 15px 25px 15px 10px;
      background-color: #000;
      color: #fff;
      font-size: 0.875rem;
      border-left: 1px solid $white;
      text-align: left;
      &:first-child {
        background-color: $gray-lighter;
        border-left: 0;
      }
      &:nth-child(2) {
        border-left: 0;
      }
    }
    th:active, td:active {
      outline: none;
    }
  }
  tfoot {
    th, td {
      padding: 10px 18px 6px 18px;
      border-top: 1px solid #111;
    }
  }
  thead {
    .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
      cursor: pointer;
      *cursor: hand;
      background-repeat: no-repeat;
      background-position: calc(100% - 0.75rem) center;
    }
    .sorting {
      @include fill($svgstore--DropDown_Arrow_Up, $white);
      background-size: 0.75rem 0.75rem;
    }
    .sorting_asc {
      @include fill($svgstore--DropDown_Arrow_Up, $white);
      background-size: 0.75rem 0.75rem;
    }
    .sorting_desc {
      @include fill($svgstore--DropDown_Arrow_Down, $white);
      background-size: 0.75rem 0.75rem;
    }
    .sorting_asc_disabled {
      // @include fill($svgstore--Caret-up, #656565);
      background-size: 0.75rem 0.75rem;
    }
    .sorting_desc_disabled {
      // @include fill($svgstore--Caret-down, #656565);
      background-size: 0.75rem 0.75rem;
    }
    .row {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        background-color: red;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  tbody {
    @include font-size(12);
    tr {
      background-color: #ffffff;
      &.selected {
        background-color: #B0BED9;
      }
    }
    th, td {
      padding: 8px 10px;
      width: 20%;
      &:first-child {
        padding: 0;
        width: 15%;
        max-width: 150px;
        img {
          display: none;
          @include mq(58em) {
            display: block;
          }
        }
      }
      &:nth-child(2) {
        font-weight: bold;
      }
    }
  }
  &.row-border tbody {
    th, td {
      border-top: 1px solid #ddd;
    }
  }
  &.display tbody {
    th, td {
      border-top: 1px solid #ddd;
    }
  }
  &.row-border tbody tr:first-child {
    th, td {
      border-top: none;
    }
  }
  &.display tbody tr:first-child {
    th, td {
      border-top: none;
    }
  }
  &.cell-border tbody {
    th, td {
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
    tr {
      th:first-child, td:first-child {
        border-left: 1px solid #ddd;
      }
      &:first-child {
        th, td {
          border-top: none;
        }
      }
    }
  }
  &.stripe tbody tr.even, &.display tbody tr.even {
    background-color: #f9f9f9;
  }
  &.stripe tbody tr.even.selected, &.display tbody tr.even.selected {
    background-color: #acbad4;
  }
  &.hover tbody tr:hover, &.display tbody tr:hover {
    background-color: #f6f6f6;
  }
  &.hover tbody tr:hover.selected, &.display tbody tr:hover.selected {
    background-color: #aab7d1;
  }
  &.order-column tbody tr > {
    .sorting_1, .sorting_2, .sorting_3 {
      background-color: #fafafa;
    }
  }
  &.display tbody tr > {
    .sorting_1, .sorting_2, .sorting_3 {
      background-color: #fafafa;
    }
  }
  &.order-column tbody tr.selected > {
    .sorting_1, .sorting_2, .sorting_3 {
      background-color: #acbad5;
    }
  }
  &.display tbody tr {
    &.selected > {
      .sorting_1, .sorting_2, .sorting_3 {
        background-color: #acbad5;
      }
    }
    &.even > .sorting_1 {
      background-color: #f1f1f1;
    }
  }
  &.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #f1f1f1;
  }
  &.display tbody tr.even > .sorting_2, &.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #f3f3f3;
  }
  &.display tbody tr.even > .sorting_3, &.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: whitesmoke;
  }
  &.display tbody tr.even.selected > .sorting_1, &.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #a6b4cd;
  }
  &.display tbody tr.even.selected > .sorting_2, &.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #a8b5cf;
  }
  &.display tbody tr.even.selected > .sorting_3, &.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #a9b7d1;
  }
  &.display tbody tr.odd > .sorting_1, &.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #fafafa;
  }
  &.display tbody tr.odd > .sorting_2, &.order-column.stripe tbody tr.odd > .sorting_2 {
    background-color: #fcfcfc;
  }
  &.display tbody tr.odd > .sorting_3, &.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: #fefefe;
  }
  &.display tbody tr.odd.selected > .sorting_1, &.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #acbad5;
  }
  &.display tbody tr.odd.selected > .sorting_2, &.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #aebcd6;
  }
  &.display tbody tr.odd.selected > .sorting_3, &.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #afbdd8;
  }
  &.display tbody tr:hover > .sorting_1, &.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #eaeaea;
  }
  &.display tbody tr:hover > .sorting_2, &.order-column.hover tbody tr:hover > .sorting_2 {
    background-color: #ececec;
  }
  &.display tbody tr:hover > .sorting_3, &.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #efefef;
  }
  &.display tbody tr:hover.selected > .sorting_1, &.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #a2aec7;
  }
  &.display tbody tr:hover.selected > .sorting_2, &.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #a3b0c9;
  }
  &.display tbody tr:hover.selected > .sorting_3, &.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #a5b2cb;
  }
  &.no-footer {
    border-bottom: 1px solid #111;
  }
  &.nowrap {
    th, td {
      white-space: nowrap;
    }
  }
  &.compact {
    thead {
      th, td {
        padding: 4px 17px 4px 4px;
      }
    }
    tfoot {
      th, td {
        padding: 4px;
      }
    }
    tbody {
      th, td {
        padding: 4px;
      }
    }
  }
  th.dt-left, td.dt-left {
    text-align: left;
  }
  th.dt-center {
    text-align: center;
  }
  td {
    &.dt-center, &.dataTables_empty {
      text-align: center;
    }
  }
  th.dt-right, td.dt-right {
    text-align: right;
  }
  th.dt-justify, td.dt-justify {
    text-align: justify;
  }
  th.dt-nowrap, td.dt-nowrap {
    white-space: nowrap;
  }
  thead {
    th.dt-head-left, td.dt-head-left {
      text-align: left;
    }
  }
  tfoot {
    th.dt-head-left, td.dt-head-left {
      text-align: left;
    }
  }
  thead {
    th.dt-head-center, td.dt-head-center {
      text-align: center;
    }
  }
  tfoot {
    th.dt-head-center, td.dt-head-center {
      text-align: center;
    }
  }
  thead {
    th.dt-head-right, td.dt-head-right {
      text-align: right;
    }
  }
  tfoot {
    th.dt-head-right, td.dt-head-right {
      text-align: right;
    }
  }
  thead {
    th.dt-head-justify, td.dt-head-justify {
      text-align: justify;
    }
  }
  tfoot {
    th.dt-head-justify, td.dt-head-justify {
      text-align: justify;
    }
  }
  thead {
    th.dt-head-nowrap, td.dt-head-nowrap {
      white-space: nowrap;
    }
  }
  tfoot {
    th.dt-head-nowrap, td.dt-head-nowrap {
      white-space: nowrap;
    }
  }
  tbody {
    th.dt-body-left, td.dt-body-left {
      text-align: left;
    }
    th.dt-body-center, td.dt-body-center {
      text-align: center;
    }
    th.dt-body-right, td.dt-body-right {
      text-align: right;
    }
    th.dt-body-justify, td.dt-body-justify {
      text-align: justify;
    }
    th.dt-body-nowrap, td.dt-body-nowrap {
      white-space: nowrap;
    }
  }
  box-sizing: content-box;
  th, td {
    box-sizing: content-box;
  }
}

/*
* Control feature layout
*/

.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
  .dataTables_length {
    float: left;
  }
  .dataTables_filter {
    // float: right;
    // text-align: right;
    input {
      width: 100%;
      margin-top: 1rem;  
      @include mq(32em) {
        
      }
      @include mq(58em) {
        width: 18rem;
        margin-top: 0;
      }
    }
  }
  .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
  }
  .dataTables_paginate {
    float: right;
    // text-align: right;
    padding-top: 1rem;
    .paginate_button {
      box-sizing: border-box;
      display: inline-block;
      min-width: 1.5em;
      padding: 0.5em 1em;
      margin-left: 2px;
      text-align: center;
      text-decoration: none !important;
      cursor: pointer;
      *cursor: hand;
      color: $white !important;
      background-color: $red;
      &.current {
        color: $black !important;
        background-color: $gray-lighter;
        &:hover {
          // color: #333 !important;
          // background-color: $green;
        }
      }
      &.disabled {
        cursor: default;
        color: #666 !important;
        border: 1px solid transparent;
        background: transparent;
        box-shadow: none;
        @include hide;
        &:hover, &:active {
          cursor: default;
          color: #666 !important;
          border: 1px solid transparent;
          background: transparent;
          box-shadow: none;
        }
      }
      &:hover, &:active {}
    }
    .ellipsis {
      padding: 0 1em;
    }
  }
  .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  }
  .dataTables_length, .dataTables_filter, .dataTables_info, .dataTables_processing, .dataTables_paginate {
    color: #333;
    @include font-size(12);
  }
  .dataTables_scroll {
    clear: both;
    div.dataTables_scrollBody {
      *margin-top: -1px;
      -webkit-overflow-scrolling: touch;
      > table > {
        thead > tr > {
          th, td {
            vertical-align: middle;
          }
        }
        tbody > tr > {
          th, td {
            vertical-align: middle;
          }
        }
        thead > tr > {
          th > div.dataTables_sizing, td > div.dataTables_sizing {
            height: 0;
            overflow: hidden;
            margin: 0 !important;
            padding: 0 !important;
          }
        }
        tbody > tr > {
          th > div.dataTables_sizing, td > div.dataTables_sizing {
            height: 0;
            overflow: hidden;
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }
  }
  &.no-footer {
    .dataTables_scrollBody {
      border-bottom: 1px solid #111;
    }
    div {
      &.dataTables_scrollHead table.dataTable, &.dataTables_scrollBody > table {
        border-bottom: none;
      }
    }
  }
  &:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper {
    .dataTables_info {
      float: none;
      // text-align: center;
      
    }
    .dataTables_paginate {
      float: none;
      // text-align: center;
      margin-top: 0.5em;
    }
  }
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper {
    .dataTables_length {
      float: none;
      // text-align: center;
      
    }
    .dataTables_filter {
      float: none;
      // text-align: center;
      margin-top: 0.5em;
      margin-bottom: 1rem;
    }
  }
}

table.dataTable {
  &.dtr-inline.collapsed {
    > tbody > tr {
      > {
        td.child, th.child {
          cursor: default !important;
        }
        td {
          &.dataTables_empty {
            cursor: default !important;
          }
          &.child:before {
            display: none !important;
          }
        }
        th.child:before, td.dataTables_empty:before {
          display: none !important;
        }
      }
      &[role="row"] > {
        td:first-child, th:first-child {
          position: relative;
          padding-left: 30px;
          cursor: pointer;
        }
        td:first-child:before, th:first-child:before {
          top: 9px;
          left: 4px;
          height: 14px;
          width: 14px;
          display: block;
          position: absolute;
          content: '';
          @include fill($svgstore--SubNav_Open, $black);
          background-size: 0.75rem 0.75rem;
          background-repeat: no-repeat;
          background-position: 100% center;
        }
      }
      &.parent > {
        td:first-child:before, th:first-child:before {
          @include fill($svgstore--SubNav_Close, $black);
          
        }
      }
    }
    &.compact > tbody > tr > {
      td:first-child, th:first-child {
        padding-left: 27px;
      }
      td:first-child:before, th:first-child:before {
        top: 5px;
        left: 4px;
        height: 14px;
        width: 14px;
        border-radius: 14px;
        line-height: 14px;
        text-indent: 3px;
      }
    }
  }
  &.dtr-column > tbody > tr {
    > {
      td.control, th.control {
        position: relative;
        cursor: pointer;
      }
      td.control:before, th.control:before {
        top: 50%;
        left: 50%;
        height: 16px;
        width: 16px;
        margin-top: -10px;
        margin-left: -10px;
        display: block;
        position: absolute;
        color: white;
        border: 2px solid white;
        border-radius: 14px;
        box-shadow: 0 0 3px #444;
        box-sizing: content-box;
        text-align: center;
        text-indent: 0 !important;
        font-family: 'Courier New', Courier, monospace;
        line-height: 14px;
        content: '+';
        background-color: #31b131;
      }
    }
    &.parent {
      td.control:before, th.control:before {
        content: '-';
        background-color: #d33333;
      }
    }
  }
  > tbody > tr.child {
    padding: 0.5em 1em;
    &:hover {
      background: transparent !important;
    }
    ul.dtr-details {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 0;
      > li {
        border-bottom: 1px solid #efefef;
        padding: 0.5em 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    span.dtr-title {
      display: inline-block;
      min-width: 75px;
      font-weight: bold;
    }
  }
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
  div {
    &.dtr-modal-display {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 50%;
      height: 50%;
      overflow: auto;
      margin: auto;
      z-index: 102;
      overflow: auto;
      background-color: #f5f5f7;
      border: 1px solid black;
      border-radius: 0.5em;
      box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
    }
    &.dtr-modal-content {
      position: relative;
      padding: 1em;
    }
    &.dtr-modal-close {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 22px;
      height: 22px;
      border: 1px solid #eaeaea;
      background-color: #f9f9f9;
      text-align: center;
      border-radius: 3px;
      cursor: pointer;
      z-index: 12;
      &:hover {
        background-color: #eaeaea;
      }
    }
    &.dtr-modal-background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 101;
      background: rgba(0, 0, 0, 0.6);
    }
  }
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}
