.profile__top {
  .grid__item--3 {
    @include mq($max: 48em) {
      width: auto;
    }
  }
  .grid__item--3-2 {
    margin-top: 1rem;
    @include mq(48em) {
      margin-top: 0;
      float: left;
      width: 66.666%;
    }
  }

  .h6 {
    margin-top: 1rem;
  }
}