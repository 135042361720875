$baseline: 1.56;
$minScreen: 320px;
$maxScreen: 1440px;


body {
  overflow-x: hidden;
  font-family: $sans-serif;
  font-weight: $book;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $sans-serif;
  line-height: $baseline;
  margin-bottom: 1rem;
}

h1,
.h1 {
  @include fluid-type(font-size, $minScreen, $maxScreen, 30px, 40px);
  font-weight: $bold;
}

h2,
.h2 {
  @include fluid-type(font-size, $minScreen, $maxScreen, 26px, 30px);
  font-weight: $bold;
}

h3,
.h3 {
  @include font-size(27);
  font-weight: $bold;
}

h4,
.h4 {
  @include font-size(21);
  font-weight: $bold;
}

h5,
.h5 {
  @include font-size(18);
  font-weight: $reg;
}

h6,
.h6 {
  @include font-size(15);
  font-weight: $bold;
}

.headline--small {
  @include font-size(15);
  font-weight: $bold;

}
a.headline--small {
  color: $red;
}

a:not([class]) {
  color: $red;
  @include underline;
  &:hover,
  &:focus {
    color: $gray;
    @include underline($gray);
  }
}


ul,
ol {
  @include spacing;
  padding-left: 2.25rem;
}

li {
  margin-bottom: 0.75rem;
}


p {
  line-height: $baseline;
  margin: 0 0 1.25rem 0;
}

.p--large {
  @include font-size(20);
}

.p--intro {
  @include font-size(18);
  line-height: 1.5;
}

.p--small {
  @include font-size(15);
}

.p--serif {
  font-family: $serif;
}


.ul--plain {
  @include clean;
  list-style: none;
}

.ul--inline {
  li {
    display: inline-block;
    margin-bottom: 0.25rem;
  }
}

blockquote {
  @include spacing(2);
  q {
    @include font-size(30);
    line-height: 1.34;
    font-family: $serif;
    &:before,
    &:after {
      content: '';
    }
  }
  cite {
    display: block;
    margin-top: 1rem;
    @extend .h5;
    font-style: normal;
  }
}

.header__inline {
  position: relative;
  margin-bottom: -0.5rem;
  a {
    position: absolute;
    top: 0.5rem;
    right: 0;
  }
}

.divider-gray {
  display: none;
  @include mq(48em) {
    border-right: 0.4rem solid $gray-lighter;
    display: block;
  }
}

.border__left__red {
  border-left: 0.5rem solid $red;
}

hr {
  border-bottom: 0.1rem solid $black;
  .background--red & {
    border-color: $white;
  }
}

.hr--gray {
  @include spacing(2);
  border-bottom: 0.5rem solid $gray-light;
}

.hr--gray-thin {
  border-bottom: .1rem solid #E5E5E5;
}

.header--snuggle {
  margin-bottom: 0;
}

.header--snuggle-half {
  margin-bottom: 0.5rem;
}

.header--bump {
  margin-bottom: 1rem;
}


.ul-lines {
  @include clean;
  list-style: none;
  @include mq(48em) {
    border-right: 0.1rem solid $gray-light;
  }

  li {
    border-bottom: 0.1rem solid $gray-light;
    padding: 0 1rem 0.5rem 1rem;
    margin-bottom: 2rem;
    margin-right: 0.25rem;
    font-family: $serif;
    font-weight: $bold;
    @include font-size(20);
  }
}

.ul-lines--alt {
  border-right: 0;
}

.text-columns {
  @include mq(48em) {
    column-count: 2;
    column-gap: 2rem;
  }
}