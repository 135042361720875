.tabs {
  @include clear;
  margin-bottom: 0;
}

.tabs__nav {
  @include clean;
  list-style: none;
  .juicer & {
    text-align: center;
  }
  .profile__bottom & {
    display: none;
    @include mq(48em) {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid $red-dark;
    }
  }
}

.tabs__nav__item {
  margin-bottom: 0;
  .juicer & {
    display: inline-block;
    margin-right: -0.19rem;
  }
  .profile__bottom & {
    margin-right: 5px;
    display: inline-block;
    background-color: $red;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.tabs__nav__item--active {
  .profile__bottom & {
    @include mq(48em) {
      position: relative;
      border: 1px solid $red;
      border-bottom: 0;
      background-color: $white;
      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 4px;
        background-color: $white;
        bottom: -3px;
        left: 0;
      }
    }
  }
}

.tabs__nav__link {
  padding: 0.75rem 1rem;
  .profile__bottom & {
    color: $red;
    @include font-size(15);

    @include mq(48em) {
      color: $white;
    }
  }
  .juicer & {
    background: $red;
    color: $white;
    @include font-size(15);
    font-weight: $reg;
    &:hover {
      background: $red-dark;
    }
  }
  .tabs__nav__item--active & {
    .profile__bottom & {
      color: $red;
    }
    .juicer & {
      position: relative;
      background: $red-dark;
      &:before {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $red-dark;
      }
    }
  }
}

.tabs__main {
  @include mq(48em) {
    margin: 0 -1rem;
    padding: 0 1rem;
  }
  @include mq(64em) {
    margin: 0 -3rem;
    padding: 0 3rem;
  }
}

.tabs__main__item {
  margin-bottom: 0.125rem;
  @include mq(48em) {
    margin: 0;
  }

}

.tabs__main__toggle {
  display: block;
  width: 100%;
  text-align: left;
  padding: 1rem;
  background-color: $red;
  color: $white;
  border-bottom: 1px solid $gray-light;
  @include font-size(15);
  .tabs__main__item--active--accordion & {
    background-color: $white;
    color: $red;
  }
  @include mq(48em) {
    display: none;
  }
}

.tabs__main__content {
  .tabs__main__item--active--accordion & {
    .profile__bottom & {
      visibility: visible;
      position: static;
      @include mq(48em) {
        position: absolute;
      }
    }
  }

  .tabs__main__item--active & {
    .profile__bottom & {
      visibility: visible;
      @include mq(48em) {
        position: static;
      }
    }

    .juicer & {
      display: block;
    }
  }
  .profile__bottom & {
    @include sr-hide;
    top: 0;
    padding: 1rem;
    @include mq(48em) {
      margin-top: 2rem;
    }

  }

  .juicer & {
    display: none;
  }
}

.tabs__main__interior {
  margin: 4rem 0;
}

.tabs__main__item--active--accordion {
  border: 1px solid $red-dark;
  @include mq(48em) {
    border: 0;
  }
}
